import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";

require('firebase/auth')

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app);

try {
  // Connect to local auth emulator if running locally
  console.log('Connecting to local Auth emulator')
  if (process.env.REACT_APP_ENVIRONMENT === 'emulator') {
    connectAuthEmulator(auth, process.env.REACT_APP_EMULATOR_AUTH_ADDRESS)
    console.log('Connecting to Emulator Auth')
  } else {
    console.log('Connecting to Cloud Auth')
  }
  console.log('Connected to Auth.')
} catch (error) {
  console.log(
    'Error setting up connection to Authentication service - ' + error
  )
}

// const analytics = getAnalytics(app)

export default app
export { auth, analytics }
