import NodeHistoryFormLogicHook from './NodeHistoryFormLogic'
import {
  TextField,
  Button,
  Grid,
  ListItemButton,
  ListItemText,
  Modal,
  Box,
  List,
  Select,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material'
import NodeHistoricalValueFormView from '../NodeHistoricalValueForm/NodeHistoricalValueFormView'
import TransactionFormView from '../TransactionForm/TransactionFormView'
import { useAuth } from '../../contexts/AuthContext'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const NodeHistoryFormView = (props) => {
  const {
    openNodeHistoryForm,
    handleOpenNodeHistoryForm,
    handleCloseNodeHistoryForm,
    openTransactionForm,
    handleOpenTransactionForm,
    handleCloseTransactionForm,
    loading,
    error,
    displayedTransactions,
    handleTransactionViewChange,
    transactionView,
    transactionOptions,
  } = NodeHistoryFormLogicHook()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box
          component="form"
          sx={{ width: '100%', bgcolor: '#ACD5AC', borderRadius: '5px' }}
        >
          <ListItemButton onClick={handleOpenNodeHistoryForm}>
            <ListItemText primary="Add Historical Value" />
          </ListItemButton>
        </Box>
        <Modal
          open={openNodeHistoryForm}
          onClose={handleCloseNodeHistoryForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <NodeHistoricalValueFormView 
              closeModal={handleCloseNodeHistoryForm}
            />
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={6}>
        <Box
          component="form"
          sx={{ width: '100%', bgcolor: '#d5acd5', borderRadius: '5px' }}
        >
          <ListItemButton onClick={handleOpenTransactionForm}>
            <ListItemText primary="Insert a transaction" />
          </ListItemButton>
        </Box>
        <Modal
          open={openTransactionForm}
          onClose={handleCloseTransactionForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <TransactionFormView closeModal={handleCloseTransactionForm} />
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          sx={{
            width: '100%',
            bgcolor: '#AFB0B8',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: 3,
          }}
        >
          <h1>Recent Transactions</h1>
          <Select
            native
            value={transactionView}
            onChange={handleTransactionViewChange}
            inputProps={{
              name: 'transactionView',
              id: 'transactionView',
            }}
          >
            {transactionOptions?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="transactions table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency Code</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Edge ID</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedTransactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{new Date(parseFloat(transaction.date)).toLocaleDateString()}</TableCell>
                  <TableCell>{transaction.vendor}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.currencyCode}</TableCell>
                  <TableCell>{transaction.network}</TableCell>
                  <TableCell>{transaction.edgeId}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default NodeHistoryFormView
