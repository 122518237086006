import { useSelector } from 'react-redux'
import { setFocusNode } from '../../slices/graphSlice'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect, useState } from 'react'
import { grey } from '@mui/material/colors'

const { useRef } = React

const GET_FORCE_GRAPH_DATA = gql`
  query GetForceGraphData($ownerUid: ID!) {
    nodes(ownerUid: $ownerUid) {
      id
      name
      currentBalance
      type
    }
    edges(ownerUid: $ownerUid) {
      id
      sourceId
      destId
      amount
    }
  }
`

const NODE_COLOR_MAP = {
  User: 'purple',
  Asset: 'green',
  Liability: 'red',
  Income: '#305c79',
  Other: 'grey',
}
/**
 * Transforms a gql query of nodes into a list for the force graph.
 * @param {*} nodes
 * @returns
 */
function transform_node_list(nodes) {
  const graph_nodes = []
  const nodeCopy = [...nodes]
  nodeCopy.sort(function (a, b) {
    return a.currentBalance - b.currentBalance
  })
  const max = nodeCopy[nodeCopy.length - 1].currentBalance
  const TOP_RATIO = 4
  nodeCopy.forEach((node) => {
    graph_nodes.push({
      id: node.id,
      name: node.name,
      group: 1,
      nodeRelSize: (node.currentBalance * TOP_RATIO) / max,
      color: NODE_COLOR_MAP[node.type] || 'grey',
    })
  })
  return graph_nodes
}
/**
 * Transforms a gql query of edges into a list for the force graph.
 * @param {*} edges
 * @returns
 */
function transform_edge_list(edges) {
  const graph_edges = []
  edges.forEach((edge) => {
    graph_edges.push({
      source: edge.sourceId,
      target: edge.destId,
      value: edge.amount,
    })
  })
  return graph_edges
}

const GraphDisplayLogicHook = () => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const fgRef = useRef()
  const [forceGraphData, setForceGraphData] = useState({
    nodes: [],
    links: [],
  })
  const { loading, error, data, refetch } = useQuery(GET_FORCE_GRAPH_DATA, {
    variables: {
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      const state = {
        nodes: transform_node_list(data.nodes),
        links: transform_edge_list(data.edges),
      }
      setForceGraphData(state)
    },
  })

  // On new node or edge, refetch data.
  const newNodeOrEdge = useSelector((state)=>state.graph.newNodeOrEdge)
  useEffect(()=>{
      refetch()
  }, [newNodeOrEdge])

  function handleNodeClick(node) {
    // Aim at node from outside it
    const distance = 40
    const distRatio = 1 + distance / Math.hypot(node.x, node.y)

    fgRef.current.centerAt(
      { x: node.x * distRatio, y: node.y * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      3000 // ms transition duration
    )
    dispatch(setFocusNode(node.id))
  }
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth * 0.66)
  const [displayHeight, setDisplayHeight] = useState(window.innerHeight * 0.66)

  return {
    fgRef,
    handleNodeClick,
    forceGraphData,
    displayWidth,
    displayHeight,
  }
}

export default GraphDisplayLogicHook
