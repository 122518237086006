import './TimelineCalViewStyle.css'
import TimelineCalLogicHook from './TimelineCalLogic'
import Select from '@mui/material/Select'
import { Button, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { RRule } from 'rrule'

const TimelineCalView = (props) => {
    const {
        startDateLocal,
        endDateLocal,
        setStartDateLocal,
        setEndDateLocal,
        updateTimeSpanRrule
    } = TimelineCalLogicHook()
    return (
        <>
        <div className="future-controls">
            <TextField
                id="startDate"
                label="startDate"
                type="date"
                value={startDateLocal}
                onChange={(event) => {
                    setStartDateLocal(event.target.value)
                }}
            />
            <TextField
                id="endDate"
                label="End Date"
                type="date"
                value={endDateLocal}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => {
                    setEndDateLocal(event.target.value)
                }}
            />
            <Button
                variant="contained"
                onClick={() => {
                    updateTimeSpanRrule()
                }}
                >
                Update
            </Button>
        </div>
        </>
    )
}
export default TimelineCalView