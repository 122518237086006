import React from 'react'
import Typography from '@mui/material/Typography'
import {
  Link,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
} from '@mui/material'
import { SignUpFormLogic } from './SignupFormLogic'
import logo from '../../../bucket-logo-purple.png'

export default function SignUpFormView() {
  const { handleSubmit, error, loading } = SignUpFormLogic()
  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" style={{ maxWidth: '5vw', alignItems: 'center', marginBottom: '.5vw' }}/>
        <h1>Welcome to Buckets</h1>
        <Grid container justifyContent="center" sx={{ gap: 2 }}>
          <Link href="/login" variant="h5" sx={{ color: 'grey.500', textDecoration: 'none', '&:hover': { color: 'grey.700' } }}>
            Sign in
          </Link>
          <Typography component="h1" variant="h5" >
            {"Sign Up"}
          </Typography>
        </Grid>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password-confirm"
            label="Confirm Password"
            type="password"
            id="password-confirm"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </>
  )
}
