import React from 'react'
import AccountsPageLogicHook from './AccountsPageLogic'
import UserAccountDisplayView from '../../components/UserAccountDisplay/UserAccountDisplayView'
import './AccountsPageStyle.css'

const AccountsPageView = () => {
    const {  } = AccountsPageLogicHook()
    return (
        <div className='accountPage'>
            <h1 className='title'>Accounts Attached</h1>
            <UserAccountDisplayView />
        </div>
    )
}

export default AccountsPageView
