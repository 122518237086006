import { useSelector } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
const { RRule } = require('rrule')

const GET_NODE_CASH_DATA = gql`
  query getNodeTimeGraphDisplay(
    $ownerUid: ID!
    $nodeId: ID!
    $timeSpanRrule: String!
  ) {
    getNodeDataOverTime(ownerUid: $ownerUid, nodeId: $nodeId, timeSpanRrule: $timeSpanRrule) {
      code
      success
      message
      dataPoints {
        date
        actualBalance
        predictedBalance
        actualMoneyOut
        predictedMoneyOut
        actualMoneyIn
        predictedMoneyIn
      }
    }
    node(ownerUid: $ownerUid, id: $nodeId) {
      currentBalance
    }
  }
`

const NodeBalanceLogicHook = () => {
  const { currentUser } = useAuth()
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  const startDateSlice = useSelector((state)=>state.graph.startDate)
  const endDateSlice = useSelector((state)=>state.graph.endDate)

  useEffect(() => {
    refetch()
  }, [startDateSlice, endDateSlice])
  

  const { loading, error, data, refetch } = useQuery(GET_NODE_CASH_DATA, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
      timeSpanRrule: new RRule({
        freq: RRule.MONTHLY,  // All time queries are monthly at this time.
        dtstart: new Date(startDateSlice),
        until: new Date(endDateSlice),
      }).toString(),
    },
  })

  const [graphData, setGraphData] = useState([])
  const [tooltipBalance, setTooltipBalance] = useState(0)
  const [predictedBalance, setPredictedBalance] = useState(0)
  const [creditsActual, setCreditsActual] = useState(0)
  const [creditsPredicted, setCreditsPredicted] = useState(0)
  const [debitsActual, setDebitsActual] = useState(0)
  const [debitsPredicted, setDebitsPredicted] = useState(0)
  const [tooltipDate, setTooltipDate] = useState('Today')

  useEffect(() => {
    if (data) {
      const modifiedDataPoints = data.getNodeDataOverTime.dataPoints.map((point) => {
        return {
          ...point,
          date: new Date(parseInt(point.date)).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        }
      })
      setGraphData(modifiedDataPoints)
    }
  }, [data, setGraphData])

  const newNodeOrEdge = useSelector((state) => state.graph.newNodeOrEdge)
  useEffect(() => {
    refetch()
  }, [newNodeOrEdge])

  

  return {
    loading,
    error,
    graphData,
    tooltipBalance,
    predictedBalance,
    creditsActual,
    creditsPredicted,
    debitsActual,
    debitsPredicted,
    setTooltipBalance,
    setPredictedBalance,
    setCreditsActual,
    setCreditsPredicted,
    setDebitsActual,
    setDebitsPredicted,
    data,
    tooltipDate,
    setTooltipDate,
  }
}
export default NodeBalanceLogicHook
