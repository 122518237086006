import React from 'react'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Paper, Link, Grid, CssBaseline } from '@mui/material'
import SignInFormView from './SignInForm/SignInFormView'
import SignUpFormView from './SignUpForm/SignUpFormView'
import ResetPasswordFormView from './ResetPasswordForm/ResetPasswordFormView'
import AuthBackground from '../../local_resources/auth-background.jpg'
import { AuthLogic } from './AuthLogic'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Buckets Banking
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

function AuthView({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main"
        maxWidth={"xs"}
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{backgroundImage: `url(${AuthBackground})`, backgroundSize: 'cover'}}
      >
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {children}
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </ThemeProvider>
  )
}

export function AuthLoginView() {
  return (
    <AuthView>
      <SignInFormView />
    </AuthView>
  )
}

export function AuthSignUpView() {
  return (
    <AuthView>
      <SignUpFormView />
    </AuthView>
  )
}

export function AuthResetPasswordView() {
  return (
    <AuthView>
      <ResetPasswordFormView />
    </AuthView>
  )
}
