import { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useAuth } from '../../contexts/AuthContext'

const GET_TRANSACTIONS_QUERY = gql`
  query GetTransactionsOnNode($ownerUid: ID!, $nodeId: ID!) {
    outgoing: getTransactionsOnNode(
      ownerUid: $ownerUid
      nodeId: $nodeId
      incoming: false
    ) {
      date
      vendor
      amount
      currencyCode
      network
      edgeId
      description
    }
    incoming: getTransactionsOnNode(
      ownerUid: $ownerUid
      nodeId: $nodeId
      incoming: true
    ) {
      vendor
      amount
      currencyCode
    }
  }
`

const NodeHistoryFormLogic = () => {
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  const { currentUser } = useAuth()
  const transactionOptions = [
    { value: 'all', label: 'All' },
    { value: 'incoming', label: 'Income' },
    { value: 'outgoing', label: 'Charges' },
  ]
  const [credits, setCredits] = useState([])
  const [debits, setDebits] = useState([])
  // Forms Local State Logic
  const [openNodeHistoryForm, setOpenNodeHistoryForm] = useState(false)
  const handleOpenNodeHistoryForm = () => {
    setOpenNodeHistoryForm(true)
  }
  const handleCloseNodeHistoryForm = () => {
    setOpenNodeHistoryForm(false)
  }
  const [openTransactionForm, setOpenTransactionForm] = useState(false)
  const handleOpenTransactionForm = () => {
    setOpenTransactionForm(true)
  }
  const handleCloseTransactionForm = () => {
    setOpenTransactionForm(false)
  }

  // Transaction List Logic
  const [transactionView, setTransactionView] = useState('')
  const handleTransactionViewChange = (event) => {
    setTransactionView(event.target.value)
  }
  const [displayedTransactions, setDisplayedTransactions] = useState([]) // [Transaction
  useEffect(() => {
    switch (transactionView) {
      case 'all':
        setDisplayedTransactions(credits.concat(debits))
        break
      case 'incoming':
        setDisplayedTransactions(debits)
        break
      case 'outgoing':
        setDisplayedTransactions(credits)
        break
      default:
        setDisplayedTransactions([])
        break
    }
  }, [transactionView, credits, debits])

  /**
   * Updates local state variable with the fetch GraphQL data on completion.
   * 
   * This is separated in order to run after the refetch of the query.
   * @param {*} data 
   */
  function handleQueryCompleted(data) {
    setCredits(data.data.outgoing)
    setDebits(data.data.incoming)
    setTransactionView('all')
  }

  const { loading, error, data, refetch } = useQuery(GET_TRANSACTIONS_QUERY, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      handleQueryCompleted(data.data)
    },
  })
  const newTransaction = useSelector((state) => state.graph.newTransaction)
  useEffect(() => {
    refetch().then(handleQueryCompleted)
  }, [newTransaction])

  return {
    openNodeHistoryForm,
    handleOpenNodeHistoryForm,
    handleCloseNodeHistoryForm,
    openTransactionForm,
    handleOpenTransactionForm,
    handleCloseTransactionForm,
    loading,
    error,
    displayedTransactions,
    handleTransactionViewChange,
    transactionView,
    transactionOptions,
  }
}
export default NodeHistoryFormLogic
