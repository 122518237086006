import { useSelector } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'

const GET_EDGES = gql`
  query GetEdges($ownerUid: ID!, $nodeId: ID!) {
    incoming: getIncomingEdges(ownerUid: $ownerUid, nodeId: $nodeId) {
      id
      sourceId
      destId
      amount
      description
    }
    outgoing: getOutgoingEdges(ownerUid: $ownerUid, nodeId: $nodeId) {
      id
      sourceId
      destId
      amount
      description
    }
  }
`

const NodeBudgetLogicHook = () => {
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  const { currentUser } = useAuth()
  const { loading_edges, error_edges, data, refetch } = useQuery(GET_EDGES, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
    },
  })
  // On new node or edge, refetch data.
  const newNodeOrEdge = useSelector((state)=>state.graph.newNodeOrEdge)
  useEffect(()=>{
      refetch()
  }, [newNodeOrEdge])

  const [fetchedIncomingEdges, setFetchedIncomingEdges] = useState([])
  const [fetchedOutgoingEdges, setFetchedOutgoingEdges] = useState([])
  useEffect(() => {
    if (data) {
      setFetchedIncomingEdges(data.incoming)
      setFetchedOutgoingEdges(data.outgoing)
    }
  }, [data, setFetchedIncomingEdges, setFetchedOutgoingEdges])

  /** Groups the edges by the source/destination node ID.
   *
   * Returns a dict of nodeId to a list of its edges.
   */
  function get_node_groupings(edges, incoming) {
    return edges.reduce((node_groups, item) => {
      const grouping_property = incoming ? item.sourceId : item.destId
      const node_group = node_groups[grouping_property] || []
      node_group.push(item)
      node_groups[grouping_property] = node_group
      return node_groups
    }, {})
  }

  return {
    loading_edges,
    error_edges,
    focus_node_id_redux,
    fetchedIncomingEdges,
    fetchedOutgoingEdges,
    get_node_groupings,
    refetch,
  }
}
export default NodeBudgetLogicHook
