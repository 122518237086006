import './PlaygroundViewStyle.css'
import PlaygroundLogicHook from './PlaygroundViewLogic.js'
import NodeQuickView from '../../components/NodeQuickView/NodeQuickView'
import GraphDisplayView from '../../components/GraphDisplay/GraphDisplayView'
import NodeInspectView from '../../components/NodeInspectView/NodeInspectView'
import NodeFullDetailView from '../NodeFullDetailView/NodeFullDetailView'
import MetricsHeaderView from '../../components/MetricsHeaderView/MetricsHeaderView'
const PlaygroundView = (props) => {
  const { graphView, setGraphView } = PlaygroundLogicHook()

  return graphView ? (
    <div className="grid-container">
      <div className="grid-sidebar">
        <div className="top-sidebar">
          <NodeQuickView />
        </div>
        <div className="bottom-sidebar">
          <NodeInspectView graphView={graphView} setGraphView={setGraphView} />
        </div>
      </div>
      <div className="grid-playarea">
        <MetricsHeaderView />
        <GraphDisplayView />
        <span>
          {' '}
          If the graph above smudges on rendering, please just click a node.
          Bear with us as this bug is fixed.
        </span>
      </div>
    </div>
  ) : (
    <NodeFullDetailView setGraphView={setGraphView} />
  )
}

export default PlaygroundView
