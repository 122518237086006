import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useSelector } from 'react-redux'

const NodeCategorySelect = (props) => {
  const currentCategory = props.currentCategory === null ? '' : props.currentCategory
  const categories = useSelector((state) => state.graph.node_types)
  const setCategoryOnSelect = props.setCategoryOnSelect
  const style = props.style

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={currentCategory}
      label="Category"
      onChange={setCategoryOnSelect}
      style={style}
    >
      <MenuItem value={null}>All</MenuItem>
      {Object.keys(categories).map((key, i) => {
        const val = categories[key]
        const name = val === null ? 'All' : val
        return (
          <MenuItem key={val} value={val}>
            {name}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default NodeCategorySelect
