import { useQuery, gql } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { useSelector } from 'react-redux'

const GET_METRICS_DATA = gql`
    query Metrics($ownerUid: ID!, $startDate: String!, $endDate: String!) {
        metrics(ownerUid: $ownerUid, startDate: $startDate, endDate: $endDate) {
            ownerUid
            actualNetWorth
            predictedNetWorth
            actualMoneyIn
            predictedMoneyIn
            actualMoneyOut
            predictedMoneyOut
            actualMoneySaved
            predictedMoneySaved
        }
  }
`

const MetricsHeaderLogicHook = () => {
    const { currentUser } = useAuth()
    const startDateSlice = useSelector((state)=>state.graph.startDate)
    const endDateSlice = useSelector((state)=>state.graph.endDate)

    const { data, loading, error } = useQuery(GET_METRICS_DATA, {
        variables: {
            ownerUid: currentUser.uid,
            startDate: startDateSlice,
            endDate: endDateSlice
        },
    }) 

    const metrics = data?.metrics || []

    return {
        metrics,
        loading,
        error,
    }
}

export default MetricsHeaderLogicHook