import './MetricsHeaderStyle.css'
import MetricsHeaderLogicHook from './MetricsHeaderLogic'
import TimelineCalView from '../TimelineCalView/TimelineCalView'

const MetricsHeaderView = (props) => {
    const {
        metrics,
        loading,
        error,
    } = MetricsHeaderLogicHook()

    return (
        <div className="metrics-header-container">
            <div className='timeline-view'>
                <h2>Metrics Across Timeline</h2>
                <TimelineCalView />
            </div>
            <div className='cards-row'>
                <div className='metric-card'>
                    <h3>Current Net Worth</h3>
                    <p>Actual: ${metrics.actualNetWorth?.toLocaleString()}</p>
                    <p className='prediction'>Expected: ${metrics.predictedNetWorth?.toLocaleString()}</p>
                </div>
                <div className='metric-card'>
                    <h3>Income</h3>
                    <p>Actual: ${metrics.actualMoneyIn?.toLocaleString()}</p>
                    <p className='prediction'>Expected: ${metrics.predictedMoneyIn?.toLocaleString()}</p>
                </div>
                <div className='metric-card'>
                    <h3>Expenses</h3>
                    <p>Actual: ${metrics.actualMoneyOut?.toLocaleString()}</p>
                    <p className='prediction'>Expected: ${metrics.predictedMoneyOut?.toLocaleString()}</p>
                </div>
                <div className='metric-card'>
                    <h3>Savings</h3>
                    <p>Actual: ${metrics.actualMoneySaved?.toLocaleString()}</p>
                    <p className='prediction'>Expected: ${metrics.predictedMoneySaved?.toLocaleString()}</p>
                </div>
            </div>
        </div>
    )
}

export default MetricsHeaderView