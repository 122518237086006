import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

const WebHeaderLogicHook = () => {
  const { logout } = useAuth()

  const navigate = useNavigate()

  async function handleLogout() {
    try {
      await logout()
      navigate('/login')
    } catch (err) {
      console.log(err)
    }
    console.log('Successfully logged out.')
  }

    function viewProfile(){
        navigate('/profile')
    }

  function viewAccounts() {
    navigate('/accounts')
  }

  return {
    handleLogout,
    viewProfile,
    viewAccounts,
  }
}

export default WebHeaderLogicHook
