import { useAuth } from '../../../contexts/AuthContext'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { analytics } from '../../../firebase'
import { logEvent } from 'firebase/analytics'

const SignUpFormLogic = () => {
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    const passwordConfirm = data.get('password-confirm')
    logEvent(analytics, 'signup_submitted')
    if (password !== passwordConfirm) {
      setError('Passwords do not match.')
      return
    }
    try {
      setError('')
      await signup(email, password)
    } catch (err) {
      console.log(err)
      setError('Failed to create an account.')
      logEvent(analytics, 'signup_failure')
    }
    logEvent(analytics, 'signup_success')
    navigate('/')
  }

  return {
    handleSubmit,
    error,
    loading,
  }
}
export { SignUpFormLogic }
