import { useState } from 'react'
import { useSelector } from 'react-redux'
import { gql, useMutation } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { announceChange } from '../../slices/graphSlice'
import { useDispatch } from 'react-redux'
import { analytics } from '../../firebase'
import { logEvent } from 'firebase/analytics'

const CREATE_NEW_NODE = gql`
  mutation CreateNewNode(
    $ownerUid: ID!
    $name: String!
    $currentBalance: Float!
    $type: String!
  ) {
    createNewNode(
      ownerUid: $ownerUid
      name: $name
      currentBalance: $currentBalance
      type: $type
    ) {
      code
      success
      message
      node {
        name
        currentBalance
        type
      }
    }
  }
`

const NewNodeFormLogic = (toggleNewNodeForm) => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.graph.node_types)
  const [nodeName, setNodeName] = useState('')
  const [currentCategory, setCurrentCategory] = useState(categories[0])
  const [currentBalance, setCurrentBalance] = useState(0)
  const [createNewNode] = useMutation(CREATE_NEW_NODE, {
    variables: {
      name: nodeName,
      currentBalance: parseFloat(currentBalance),
      type: currentCategory,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      dispatch(announceChange('New Node created'))
      toggleNewNodeForm(data?.createNewNode?.node?.name)
      logEvent(analytics, 'new_node_created')
    },
  })

  function submitNewNodeForm(e) {
    createNewNode()
    e.preventDefault()
  }
  function setCategoryOnSelect(e) {
    setCurrentCategory(e.target.value)
  }

  return {
    nodeName,
    setNodeName,
    currentCategory,
    setCategoryOnSelect,
    currentBalance,
    setCurrentBalance,
    submitNewNodeForm,
  }
}

export default NewNodeFormLogic
