import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFocusNode } from '../../slices/graphSlice'
import { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'

const GET_NODES = gql`
  query GetNodes($ownerUid: ID!, $nodeInputFilter: NodeInputFilter) {
    nodes(ownerUid: $ownerUid, nodeInputFilter: $nodeInputFilter) {
      id
      name
      currentBalance
      type
    }
  }
`

const NodeQuickViewLogicHook = () => {
  const [newNodeMode, toggleNewNodeMode] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [substringFilter, setStringFilter] = useState('')
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  // Use a stateful variable to store the nodes, so that we can filter them.
  const [categorySeparatedNodes, setCategorySeparatedNodes] = useState({})
  const { loading, error, data, refetch } = useQuery(GET_NODES, {
    variables: {
      // Issue actually filtering.
      nodeInputFilter: {
        category: currentCategory,
        subName: substringFilter,
      },
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) =>{
      if (data){
        // Filter nodes by the categories we've received.
        var category_filtered = data.nodes.reduce((acc, node) => {
          if (!acc[node.type]) {
            acc[node.type] = [];
          }
          acc[node.type].push(node);
          return acc;
        }, {});
      }
      setCategorySeparatedNodes(category_filtered)
    }
  })

  useEffect(() => {
    refetch()
  }, [focus_node_id_redux])

  function setNewFocusNodeFunc(node_id) {
    dispatch(setFocusNode(node_id))
  }

  function toggleNewNodeForm() {
    toggleNewNodeMode(!newNodeMode)
    refetch()
  }
  function filterNodeListByString(e) {
    setStringFilter(e.target.value)
  }

  function setCategoryOnSelect(e) {
    setCurrentCategory(e.target.value)
  }

  return {
    setNewFocusNodeFunc,
    currentCategory,
    setCategoryOnSelect,
    filterNodeListByString,
    newNodeMode,
    toggleNewNodeForm,
    categorySeparatedNodes
  }
}

export default NodeQuickViewLogicHook
