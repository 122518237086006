import React from 'react'
import Typography from '@mui/material/Typography'
import { Link, Grid, Box, TextField, Alert, Button } from '@mui/material'
import { ResetPasswordFormLogic } from './ResetPasswordFormLogic'

export default function ResetPasswordFormView() {
  const { handleSubmit, error, loading, message } = ResetPasswordFormLogic()
  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      {message && <Alert severity="info">{message}</Alert>}
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlined />
            </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {'Remember your password? Try logging in'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
