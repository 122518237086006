import { TextField, Button, Grid, Select } from '@mui/material'
import { TransactionFormLogicHook } from './TransactionFormLogic'

const TransactionFormView = (props) => {
  const closeModal = props.closeModal
  const {
    amount,
    setAmount,
    date,
    setDate,
    currencyCode,
    setCurrencyCode,
    vendor,
    setVendor,
    network,
    setNetwork,
    edgeId,
    setEdgeId,
    description,
    setDescription,
    handleSubmit,
    data,
  } = TransactionFormLogicHook(closeModal)

  return (
    <form onSubmit={handleSubmit}>
      <h1>Insert a past Transaction</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            native
            fullWidth
            id="edgeId"
            label="Edge"
            name="edgeId"
            value={edgeId}
            onChange={(e) => {
              setEdgeId(e.target.value)
            }}
          >
            {data &&
              data.edges.map((edge) => (
                <option key={edge.id} value={edge.id}>
                  {edge.description}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="amount"
            label="Amount"
            name="amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="date"
            label="Date"
            name="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="currencyCode"
            label="Currency Code"
            name="currencyCode"
            value={currencyCode}
            onChange={(e) => setCurrencyCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="vendor"
            label="Vendor"
            name="vendor"
            placeholder='e.g. "Starbucks"'
            value={vendor}
            onChange={(e) => setVendor(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="network"
            label="Network"
            name="network"
            placeholder='e.g. "ACH/ETH"'
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="description"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
export default TransactionFormView
