import React, { useContext, useEffect, useState } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth'
import { auth, analytics } from '../firebase'
import { logEvent, setUserId } from 'firebase/analytics'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()

  function signup(email, password) {
    createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password, remember = false) {
    auth
      .setPersistence(
        remember ? browserLocalPersistence : browserSessionPersistence
      )
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function logout() {
    logEvent(analytics, 'signout')
    return signOut(auth)
  }
  function resetPassword(email) {
    logEvent(analytics, 'reset_password')
    return sendPasswordResetEmail(auth, email)
  }

  function updateAccountEmail(email) {
    logEvent(analytics, 'update_email')
    return updateEmail(currentUser, email)
  }

  function updateAccountPassword(password) {
    logEvent(analytics, 'update_password')
    return updatePassword(currentUser, password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user){
        setCurrentUser(user)
        setUserId(analytics, user.uid)
      } else {
        setCurrentUser(null)
        setUserId(analytics, null)
      }
    })
    return unsubscribe
  }, [auth, analytics])

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateAccountEmail,
    updateAccountPassword,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
