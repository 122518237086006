import './GraphDisplayStyle.css'
import GraphDisplayLogicHook from './GraphDisplayLogic'
import { ForceGraph2D } from 'react-force-graph'

const GraphDisplayView = (props) => {
  const {
    fgRef,
    handleNodeClick,
    forceGraphData,
    displayWidth,
    displayHeight,
  } = GraphDisplayLogicHook()

  return (
      <div className="force-graph-styling">
        <ForceGraph2D
          ref={fgRef}
          backgroundColor={'#f7f8fc'}
          graphData={forceGraphData}
          onNodeClick={handleNodeClick}
          onNodeHover={() => {}}
          linkLabel="value"
          LinkThreeObjectExtend={true}
          linkDirectionalArrowLength={4}
          linkPositionUpdate={(sprite, { start, end }) => {
            const middlePos = Object.assign(
              ...['x', 'y', 'z'].map((c) => ({
                [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
              }))
            )

            // Position sprite
            Object.assign(sprite.position, middlePos)
          }}
          cooldownTicks={100}
          minZoom={3}
          maxZoom={10}
          centerAt={(0, 0)}
          onEngineStop={() => {
            console.log(fgRef)
            fgRef.current.zoomToFit(400)
          }}
          nodeVal={(node) => node.nodeRelSize}
          nodeLabel={(node) => node.name}
          nodeColor={(node) => node.color}
          nodeCanvasObjectMode={() => 'after'}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.name
            const fontSize = 18 / globalScale
            ctx.font = `${fontSize}px Sans-Serif`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'black'
            ctx.fillText(label, node.x, node.y + fontSize / 2)
          }}
        />
      </div>
  )
}

export default GraphDisplayView
