import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import EditEdgeFormView from '../../EditEdgeForm/EditEdgeFormView'
import React from 'react'
import { NestedBudgetListItemLogic, BudgetListItemLogic } from './BudgetListLogic'
import { IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const NestedBudgetListItem = (props) => {
  const { data } = NestedBudgetListItemLogic(
    props.edge_list[0].id,
    props.nodeID
  )
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  let total = 0
  props.edge_list.map((edge) => {
    total += edge.amount
  })
  return (
    <div>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: open ? 'lightgrey' : 'inherit',
          display: 'flex',
          flexDirection: 'row',
          minWidth: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div className='name-section'>
          <ListItemText primary={'(' + props.edge_list.length + ')\t' + data?.node.name} />
        </div>
        <div className='schedule-section'></div>
        {open ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'right',
            }}
          >
            <Tooltip title="Close">
              <IconButton style={{
                maxWidth: '10rem',
                backgroundColor: 'grey',
                color: 'white',
                marginRight: '1rem',
              }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>   
          </div>
        ) : (
          <>
            <div className='amount-section'>
              <ListItemButton
                sx={{
                  alignItems: 'right',
                }}
              >
                <ListItemText
                  primary={'$' + total}
                />
              </ListItemButton>
            </div>
            <div className='action-section'>
              <Tooltip title="Expand">
                <IconButton style={{
                  maxWidth: '10rem',
                  backgroundColor: 'grey',
                  color: 'white',
                  marginRight: '1rem',
                }}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}

      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding
        sx={{
          minWidth: '100%',
        }}>
          {props.edge_list.map((edge) => {
            return (
              <EditEdgeFormView
                edgeId={edge.id}
                nodeID={props.nodeID}
                onSubmit={props.onSubmit}
                nested={true}
              />
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}

const BudgetListView = (props) => {
  const node_groupings = props.node_groupings
  const incoming = props.incoming
  const onSubmit = props.onSubmit

  const listStyle = {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
  return (
    <List style={listStyle} aria-labelledby="nested-list-subheader">
      {Object.keys(node_groupings).map((key, index) => {
        const nodeID = key
        const edge_list = node_groupings[key]
        return edge_list.length > 1 ? (
          <div className='edge-item'>
            <NestedBudgetListItem
              incoming={incoming}
              edge_list={edge_list}
              nodeID={nodeID}
              onSubmit={onSubmit}
            />
          </div>
        ) : (
          <div className='edge-item'>
            <ListItemButton>
              <EditEdgeFormView
                edgeId={edge_list[0].id}
                nodeID={nodeID}
                onSubmit={onSubmit}
                nested={false}
              />
            </ListItemButton>
          </div>
        )
      })}
    </List>
  )
}

export default BudgetListView
