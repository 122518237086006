import { useSelector } from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useDispatch } from 'react-redux'
import { setFocusNode } from '../../slices/graphSlice'

const GET_NODE = gql`
  query Node($ownerUid: ID!, $nodeId: ID!) {
    node(ownerUid: $ownerUid, id: $nodeId) {
      name
      type
      currentBalance
    }
  }
`

const EDIT_NODE = gql`
  mutation EditNodeBalance(
    $ownerUid: ID!
    $nodeId: ID!
    $currentBalance: Float
    $name: String
  ) {
    editNode(
      ownerUid: $ownerUid
      nodeId: $nodeId
      currentBalance: $currentBalance
      name: $name
    ) {
      code
      success
      message
      node {
        id
        name
        currentBalance
        type
      }
    }
  }
`

const REMOVE_NODE = gql`
  mutation RemoveNode($ownerUid: ID!, $nodeId: ID!) {
    removeNode(ownerUid: $ownerUid, nodeId: $nodeId) {
      code
      success
      message
    }
  }
`

const ADD_HISTORICAL_DATA = gql`
  mutation addHistoricalData(
    $ownerUid: ID!
    $nodeId: ID!
    $date: String!
    $balance: Float!
  ) {
    addHistoricalData(
      ownerUid: $ownerUid
      nodeId: $nodeId
      date: $date
      balance: $balance
    ) {
      code
      message
      success
    }
  }
`

const NodeInspectViewLogicHook = () => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)

  const { loading, error, data, refetch } = useQuery(GET_NODE, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
    },
  })
  const [nodeName, setNodeName] = useState('')
  const [currentBalance, setCurrentBalance] = useState(0)

   // When new balance values are added, add them to the historical data of the node.
  const [addHistoricalData] = useMutation(ADD_HISTORICAL_DATA, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
      date: new Date().toISOString(),
      balance: parseFloat(currentBalance),
    },
    onCompleted: (data) => {
      console.log('Mutation completed- new node historical data ', data)
    },
  })

  const [editNode] = useMutation(EDIT_NODE, {
    variables: {
      nodeId: focus_node_id_redux,
      name: nodeName,
      ownerUid: currentUser.uid,
      currentBalance: parseFloat(currentBalance),
    },
    onCompleted: (data) => {
      refetch()
      addHistoricalData()
    },
  })

  const [removeNode] = useMutation(REMOVE_NODE, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      dispatch(setFocusNode(''))
    },
  })

  useEffect(() => {
    refetch()
  }, [focus_node_id_redux, refetch])

  useEffect(() => {
    if (data && focus_node_id_redux) {
      if (data.node) {
        setNodeName(data.node.name)
        setCurrentBalance(data.node.currentBalance)
      }
    }
  }, [data, focus_node_id_redux])

  function EditNodeWrapper(event) {
    event.preventDefault()
    editNode()
  }
  const [openBudgetView, toggleBudgetView] = useState(true)
  const [openTransactionView, toggleTransactionView] = useState(false)
  const [openAccountsView, toggleAccountsView] = useState(false)

  return {
    loading,
    error,
    data,
    nodeName,
    setNodeName,
    EditNodeWrapper,
    removeNode,
    openBudgetView,
    toggleBudgetView,
    openTransactionView,
    toggleTransactionView,
    openAccountsView,
    toggleAccountsView,
    currentBalance,
    setCurrentBalance,
  }
}

export default NodeInspectViewLogicHook
