import NewNodeFormLogic from './NewNodeFormLogic'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import NodeCategorySelect from '../NodeCategorySelect/NodeCategorySelectView'

const NewNodeForm = (props) => {
  const toggleNewNodeForm = props.toggleNewNodeForm

  const {
    nodeName,
    setNodeName,
    currentCategory,
    setCategoryOnSelect,
    currentBalance,
    setCurrentBalance,
    submitNewNodeForm,
  } = NewNodeFormLogic(toggleNewNodeForm)

  return (
    <form className="new-node-form-style" onSubmit={() => {}}>
      <h2>Add a new node to the graph</h2>
      <TextField
        id="node-name"
        className="text"
        onInput={(e) => {
          setNodeName(e.target.value)
        }}
        label="Node name"
        variant="outlined"
        value={nodeName}
        placeholder="Node name"
        size="medium"
      />
      <NodeCategorySelect
        currentCategory={currentCategory}
        setCategoryOnSelect={setCategoryOnSelect}
        style={{ width: '100%', maxHeight: '25%' }}
      />
      {['Income Source', 'Expense', 'External User'].includes(currentCategory) ? (
        <></>
      ):(
        <div className="new-node-detail-row-form-style">
          <InputLabel
            style={{ height: '50px', lineHeight: '50px', textAlign: 'center' }}
          >
            Current Balance
          </InputLabel>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={currentBalance}
            onInput={(e) => {
              setCurrentBalance(e.target.value)
            }}
          />
        </div>
      )}
      <Button
        onClick={(e) => {
          submitNewNodeForm(e, toggleNewNodeForm)
        }}
      >
        Add new Node
      </Button>
    </form>
  )
}

export default NewNodeForm
