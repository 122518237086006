import { useSelector } from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { useState } from 'react'
import { analytics } from '../../firebase'
import { logEvent } from 'firebase/analytics'

const GET_NODE = gql`
  query Node($ownerUid: ID!, $nodeId: ID!) {
    node(ownerUid: $ownerUid, id: $nodeId) {
      name
      type
      note
      accounts {
        id
        currentBalance
        name
      }
    }
  }
`

const EDIT_NODE = gql`
  mutation EditNode(
    $ownerUid: ID!
    $nodeId: ID!
    $note: String
    $type: String
  ) {
    editNode(
      ownerUid: $ownerUid
      nodeId: $nodeId
      note: $note
      type: $type
    ) {
      code
      success
      message
      node {
        id
        type
        note
      }
    }
  }
`

const ATTACH_ACCOUNTS = gql`
  mutation attachAccountsToNode(
    $ownerUid: ID!
    $nodeId: ID!
    $newAccountIds: [String]
  ) {
    editNode(
      ownerUid: $ownerUid
      nodeId: $nodeId
      newAccountIds: $newAccountIds
    ) {
      code
      message
      success
    }
  }
`

const NodeFullDetailLogicHook = () => {
  const { currentUser } = useAuth()
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  const categories = useSelector((state) => state.graph.node_types)
  const [localNote, setLocalNote] = useState('')
  const [localType, setLocalType] = useState('')
  
  const { loading, error, data, refetch } = useQuery(GET_NODE, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      logEvent(analytics, 'node_detail_viewed', {
        node_id: focus_node_id_redux,
      })
      setLocalType(
        data?.node.type
      )
      setLocalNote(
        data?.node.note
      )
    }
  })
  function refreshType() {
    setLocalType(data?.node.type)
  }
  const [ editNode ] = useMutation(EDIT_NODE, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
      note: localNote,
      type: localType
    },
    onCompleted: (data) => {
      refetch()
    },
  })
  

  // Handle the new account popup
  const [openAccount, setOpenAccounts] = useState(false)
  const handleCloseAccounts = () => {
    setOpenAccounts(false)
  }
  const handleOpenAccounts = () => {
    setOpenAccounts(true)
  }
  const [childAccountSelector, setSelectedAccounts] = useState(new Set())
  const [attachAccountsToNodeDB] = useMutation(ATTACH_ACCOUNTS, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
      newAccountIds: Array.from(childAccountSelector).join(','),
    },
    onCompleted: (data) => {
      refetch()
      handleCloseAccounts()
    },
  })
  function attachAccountsToNode(event) {
    event.preventDefault()
    attachAccountsToNodeDB()
  }

  // Node Tabs Control
  const [value, setValue] = useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return {
    loading,
    error,
    data,
    openAccount,
    handleOpenAccounts,
    handleCloseAccounts,
    childAccountSelector,
    setSelectedAccounts,
    attachAccountsToNode,
    value,
    handleChange,
    localNote,
    setLocalNote,
    editNode,
    categories,
    localType,
    setLocalType,
    refreshType
  }
}

export default NodeFullDetailLogicHook
