import { useState } from 'react'

const PlaygroundLogicHook = () => {
  const [graphView, setGraphView] = useState(true)

  return {
    graphView,
    setGraphView,
  }
}
export default PlaygroundLogicHook
