import React from 'react'
import './RecurringEdgeFormStyle.css'
import { RecurringEdgeFormViewLogicHook } from './RecurringEdgeFormViewLogic'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { FormControl, InputLabel, TextField } from '@mui/material'

const RecurringEdgeFormView = ({ edgeId, handleCloseDetail, calendarFetch }) => {
  const {
    startDate,
    setStartDate,
    frequency,
    endDate,
    setEndDate,
    setFrequency,
    onSubmit,
    data,
  } = RecurringEdgeFormViewLogicHook(edgeId, handleCloseDetail, calendarFetch)

  return (
    <>
      <h1>Recurring Edge Form</h1>
      <p>Edge ID: {edgeId}</p>
      {/* Create a calendar selector */}
      <div className="recurrence-group">
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          value={startDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setStartDate(event.target.value)
          }}
        />
        <FormControl>
          <InputLabel id="frequency-label">Frequency</InputLabel>
          <Select
            label="Frequency"
            value={frequency}
            onChange={(event) => {
              setFrequency(event.target.value)
            }}
          >
            <MenuItem key="once" value='null'>
              Just once
            </MenuItem>
            <MenuItem key="weekly" value="weekly">
              Weekly
            </MenuItem>
            <MenuItem key="monthly" value="monthly">
              Monthly
            </MenuItem>
            <MenuItem key="yearly" value="yearly">
              Yearly
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="endDate"
          label="End Date"
          type="date"
          value={endDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setEndDate(event.target.value)
          }}
        />
      </div>
      <button onClick={onSubmit}>Submit</button>
    </>
  )
}

export default RecurringEdgeFormView
