import './NodeBalanceStyle.css'
import NodeBalanceLogicHook from './NodeBalanceLogic'
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  Bar,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts'
import { Tooltip as HelpTip } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function formatYAxisTick(value) {
  return `$${value.toLocaleString()}`;
}

const NodeBalanceView = (props) => {
  const {
    loading,
    error,
    graphData,
    tooltipBalance,
    predictedBalance,
    creditsActual,
    creditsPredicted,
    debitsActual,
    debitsPredicted,
    setTooltipBalance,
    setPredictedBalance,
    setCreditsActual,
    setCreditsPredicted,
    setDebitsActual,
    setDebitsPredicted,
    data,
    tooltipDate,
    setTooltipDate,
  } = NodeBalanceLogicHook()
  if (loading || !graphData) return <h1>Loading</h1>
  if (error) return <h1>In Error</h1>

  function CustomTooltip({ active, payload, label }) {
    if (payload === null){
      return null
    }
    if (payload.length > 0) {
      setTooltipBalance(payload[0]?.payload.actualBalance?.toLocaleString())
      setPredictedBalance(payload[0]?.payload.predictedBalance?.toLocaleString())
      setTooltipDate(new Date(payload[0]?.payload.date).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      }));
      setCreditsActual(payload[0]?.payload.actualMoneyOut?.toLocaleString())
      setCreditsPredicted(payload[0]?.payload.predictedMoneyOut?.toLocaleString())
      setDebitsActual(payload[0]?.payload.actualMoneyIn?.toLocaleString())
      setDebitsPredicted(payload[0]?.payload.predictedMoneyIn?.toLocaleString())
    } else {
      setTooltipBalance(data?.node?.currentBalance || 'No data')
      setTooltipDate('Today')
    }
    return null
  }

  return (
    <div className='fullContainer'>
      <div className='nodeQuickView'>
        <div className='date'>
          <h1>{tooltipDate}</h1>
        </div>
        <div className='card'>
          <div className='textWithHelp'>
            <h3>Balance</h3>
            <HelpTip title='Actual and expected balance by the hovered date.' fontSize='2rem'>
              <HelpOutlineIcon />
            </HelpTip>
          </div>
          <p className='actual'>${tooltipBalance}</p>
          <p className='predicted'>${predictedBalance}</p>
        </div>
        <div className='card'>
          <div className='textWithHelp'>
            <h3>Money In</h3>
            <HelpTip title='Actual and expected cashflow into node after date' fontSize='1.2rem'>
              <HelpOutlineIcon />
            </HelpTip>
          </div>
          <p className='actual'>${debitsActual}</p>
          <p className='predicted'>${debitsPredicted}</p>
        </div>
        <div className='card'>
          <div className='textWithHelp'>
            <h3>Money Out</h3>
            <HelpTip title='Actual and expected cashflow out of node after date' fontSize='1.2rem'>
              <HelpOutlineIcon />
            </HelpTip>
          </div>
          <p className='actual'>${creditsActual}</p>
          <p className='predicted'>${creditsPredicted}</p>
        </div>
      </div>
      
      <ResponsiveContainer width="95%" height={400}>
        <ComposedChart
          width="100%"
          height={400}
          data={graphData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" tickFormatter={formatYAxisTick}>
            <Label
              value="Balance (in USD)"
              position={'left'}
              angle={-90}
              offset={13}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <YAxis yAxisId="right" orientation="right" tickFormatter={formatYAxisTick}>
            <Label
                value="Cumulative Flow (in USD)"
                angle={-90}
                offset={10}
                position="right"
                style={{ textAnchor: 'middle' }}
              />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />          
          <Line type="monotone" yAxisId="left" dataKey="actualBalance" stroke="#800080" />
          <Line type="monotone" yAxisId="left" strokeDasharray="5 5" dataKey="predictedBalance" stroke="#8884d8" />
          <Bar dataKey="actualMoneyOut" yAxisId="right" fill="rgba(232,93,45)" />
          <Bar dataKey="predictedMoneyOut" yAxisId="right" fill="rgba(232,93,45, 0.6)" />
          <Bar dataKey="actualMoneyIn" yAxisId="right" fill="rgba(87,240,118)" />
          <Bar dataKey="predictedMoneyIn" yAxisId="right" fill="rgba(87,240,118, 0.6)" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default NodeBalanceView
