import { setStartDate, setEndDate } from '../../slices/graphSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { analytics } from '../../firebase'
import { logEvent } from 'firebase/analytics'

const { RRule } = require('rrule')


const TimelineCalLogicHook = () => {
    const dispatch = useDispatch()
    const startDateSlice = useSelector((state)=>state.graph.startDate)
    const endDateSlice = useSelector((state)=>state.graph.endDate)
    // Use local states during tuning before refetching data.
    const [startDateLocal, setStartDateLocal] = useState(
        startDateSlice.split('T')[0]
    )
    const [endDateLocal, setEndDateLocal] = useState(
        endDateSlice.split('T')[0]
    )
    /**
     * This function is used to update the global time span period of inspection.
     * Only make this call after the user has confirmed the bounds of their query.
     */
    function updateTimeSpanRrule() {
        const startDate = new Date(startDateLocal)
        const endDate = new Date(endDateLocal)
        // Set date range global state and announce the change.
        dispatch(setStartDate(startDate.toISOString()))
        dispatch(setEndDate(endDate.toISOString()))
        logEvent(analytics, 'updated_timeline_view')
    }
    return {
        startDateLocal,
        endDateLocal,
        setStartDateLocal,
        setEndDateLocal,
        updateTimeSpanRrule
    }
}
export default TimelineCalLogicHook