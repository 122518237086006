import './NodeQuickViewStyle.css'
import NodeQuickViewLogicHook from './NodeQuickViewLogic'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import NewNodeForm from '../NewNodeForm/NewNodeFormView'
import NodeCategorySelect from '../NodeCategorySelect/NodeCategorySelectView'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const NodeList = (props) => {
  const nodesByType = props.nodesByType
  const setNewFocusNodeFunc = props.setNewFocusNodeFunc
  var rows = []

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#008080', color: 'white'}}>
            <TableCell>Node Name</TableCell>
            <TableCell align="right">Node Type</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>

        {nodesByType !== null ? (
          Object.keys(nodesByType).forEach((type) => {
            rows.push(
              <TableRow sx={{ backgroundColor: 'grey', maxHeight: '.2rem', color: 'white'}}>
                <TableCell colSpan={3} align='center'>{type}</TableCell>
              </TableRow>
            )
            nodesByType[type].forEach((node, index) => {
              rows.push(
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer' }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      setNewFocusNodeFunc(node.id)
                    }}
                  >
                    {node.name}
                  </TableCell>
                  <TableCell align="right">{node.type}</TableCell>
                  <TableCell align="right">{node.currentBalance}</TableCell>
                </TableRow>
              )
            })
          })
        ) : (
          rows.push(
            <TableRow>
              <TableCell colSpan={3}>No nodes found</TableCell>
            </TableRow>
          )
        )
        }
        <TableBody>
          {rows}
        </TableBody>
        
        
      </Table>
    </TableContainer>
  )
}

const SearchBar = (props) => {
  const currentCategory = props.currentCategory
  const setCategoryOnSelect = props.setCategoryOnSelect
  const filterNodesByStr = props.filterNodesByStr

  return (
    <form className="search-bar-style">
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => filterNodesByStr(e)}
        label="Search for nodes"
        variant="outlined"
        placeholder="Search"
        size="large"
        style={{ minHeight: '100%', flex: 1 }}
      />
      {/* Search icon: <IconButton  type="submit" aria-label="search" /> */}
      <NodeCategorySelect
        currentCategory={currentCategory}
        setCategoryOnSelect={setCategoryOnSelect}
        style={{ width: '30%', maxHeight: '80%' }}
      />
    </form>
  )
}

const NodeNavBar = (props) => {
  const {
    currentCategory,
    setCategoryOnSelect,
    filterNodeListByString,
    newNodeMode,
    toggleNewNodeForm,
  } = props
  return (
    <div className="quick-view-style">
      <div className="quick-view-header-style">
        {newNodeMode ? (
          <div style={{ width: '60%' }}></div>
        ) : (
          <SearchBar
            currentCategory={currentCategory}
            setCategoryOnSelect={setCategoryOnSelect}
            filterNodesByStr={filterNodeListByString}
          />
        )}
        <Button
          variant="contained"
          type="submit"
          size="small"
          onClick={toggleNewNodeForm}
        >
          {newNodeMode ? 'x' : '+'}
        </Button>
      </div>
      <Modal
        open={newNodeMode}
        onClose={toggleNewNodeForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1>New Node</h1>
          <NewNodeForm toggleNewNodeForm={toggleNewNodeForm} />
        </Box>
      </Modal>
    </div>
  )
}

const NodeQuickView = (props) => {
  const {
    setNewFocusNodeFunc,
    currentCategory,
    setCategoryOnSelect,
    filterNodeListByString,
    newNodeMode,
    toggleNewNodeForm,
    categorySeparatedNodes
  } = NodeQuickViewLogicHook()

  return (
    <div>
      <NodeNavBar
        currentCategory={currentCategory}
        setCategoryOnSelect={setCategoryOnSelect}
        filterNodeListByString={filterNodeListByString}
        newNodeMode={newNodeMode}
        toggleNewNodeForm={toggleNewNodeForm}
      />
      <NodeList
        nodesByType={categorySeparatedNodes}
        setNewFocusNodeFunc={setNewFocusNodeFunc}
      ></NodeList>
    </div>
  )
}

export default NodeQuickView
