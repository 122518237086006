import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import UserAccountDisplayLogic from './UserAccountDisplayLogic'
import './UserAccountDisplayStyle.css'
import Button from '@mui/material/Button'

const UserAccountDisplayView = ({showSelector, parentSetSelectedAccounts}) => {
    const {
        data,
        open,
        exit,
        ready,
        linkToken,
        accountSelectedFunction,
        numberOfNodesPerAccount
    } = UserAccountDisplayLogic(parentSetSelectedAccounts)
    return (
        <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                        <TableCell align="right">Account Type</TableCell>
                        <TableCell align="right">Balance</TableCell>
                        <TableCell align="right">Number of Nodes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { data ? 
                    Object.keys(data?.accounts).map((key, index) => {
                        const account = data.accounts[key]
                        return (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                { showSelector ?
                                    <input 
                                        type="checkbox"
                                        onChange={(e) => accountSelectedFunction(e, account.id)}
                                    />
                                    : <></>
                                }
                                <TableCell component="th" scope="row">
                                    {account.name}
                                </TableCell>
                                <TableCell align="right">{account.type}</TableCell>
                                <TableCell align="right">{account.currentBalance}</TableCell>
                                <TableCell align="right">{numberOfNodesPerAccount(account.id)}</TableCell>
                            </TableRow>
                        )
                    })
                    : <></>
                }
                </TableBody>
            </Table>
        </TableContainer>
        <div id='newAccountButton'>
        <Button variant="contained" color="primary" disabled={!ready}
            onClick={() => open()}>
            Link New Account
        </Button>
        </div>
        </>
    )
}

export default UserAccountDisplayView
