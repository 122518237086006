import { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useAuth } from '../../../contexts/AuthContext'
import { rrulestr, RRule } from 'rrule'
import { announceChange } from '../../../slices/graphSlice'
import { useDispatch } from 'react-redux'

const GET_EDGE_QUERY = gql`
  query GET_EDGE_DETAIL($ownerUid: ID!, $edgeId: ID!) {
    edge(ownerUid: $ownerUid, id: $edgeId) {
      rrule
    }
  }
`

const SET_RECURRING_EDGE = gql`
  mutation Mutation($ownerUid: ID!, $edgeId: ID!, $rrule: String) {
    editEdge(ownerUid: $ownerUid, edgeId: $edgeId, rrule: $rrule) {
      code
      message
      success
    }
  }
`

function transformDateToStr(date) {
  return date.toISOString().split('T')[0]
}

function transformInputToRruleString(startDate, endDate, frequency) {
  const rrule = new RRule({
    dtstart: new Date(Date.parse(startDate)),
    freq: frequency === null ? RRule.YEARLY : frequency,
    interval: frequency === null ? 1 : null,  // This is the default value, multiple occurences isn't support at this moment
    count: frequency === null ? 1 : null,
    until: endDate === null ? null : new Date(Date.parse(endDate)),
  })
  const rruleStr = rrule.toString()
  return rruleStr
}

const RecurringEdgeFormViewLogicHook = (edgeId, handleCloseDetail, calendarFetch) => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const [editEdgeRule] = useMutation(SET_RECURRING_EDGE, {
    onCompleted: (data) => {
      handleCloseDetail()
      dispatch(announceChange(''))
      calendarFetch()
    },
  })
  const [frequency, setFrequency] = useState('once')
  const today = new Date()
  const [startDate, setStartDate] = useState(transformDateToStr(today))
  const [endDate, setEndDate] = useState(null)
  const { loading, error, data } = useQuery(GET_EDGE_QUERY, {
    variables: {
      ownerUid: currentUser.uid,
      edgeId: edgeId,
    },
    onCompleted: (data) => {
      const rrule = RRule.parseString(data.edge.rrule)
      const startDate = new Date(rrule.dtstart)
      setStartDate(transformDateToStr(startDate))
      const endDate = rrule.until
      if (endDate !== null) {
        setEndDate(transformDateToStr(endDate))
      }
      if (rrule.freq === RRule.MONTHLY) {
        setFrequency('monthly')
      } else if (rrule.freq === RRule.WEEKLY) {
        setFrequency('weekly')
      } else if (rrule.freq === RRule.YEARLY) {
        setFrequency('yearly')
      } else {
        setFrequency(null)
      }
    },
  })

  function onSubmit(event) {
    event.preventDefault()
    let rruleFrequency = frequency
    if (frequency === 'weekly') {
      rruleFrequency = RRule.WEEKLY
    } else if (frequency === 'monthly') {
      rruleFrequency = RRule.MONTHLY
    } else if (frequency === 'yearly') {
      rruleFrequency = RRule.YEARLY
    } else {
      rruleFrequency = null
    }
    try {
      const rruleStr = transformInputToRruleString(
        startDate,
        endDate,
        rruleFrequency
      )
      editEdgeRule({
        variables: {
          ownerUid: currentUser.uid,
          edgeId: edgeId,
          rrule: rruleStr,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    startDate,
    setStartDate,
    frequency,
    endDate,
    setEndDate,
    setFrequency,
    onSubmit,
    data,
  }
}

export {
  RecurringEdgeFormViewLogicHook,
  transformDateToStr,
  transformInputToRruleString,
}
