import { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { useSelector, useDispatch } from 'react-redux'
import { announceChange } from '../../slices/graphSlice'
import { analytics } from '../../firebase'
import { logEvent } from 'firebase/analytics'

const CREATE_NEW_EDGE = gql`
  mutation CreateNewEdge(
    $ownerUid: ID!
    $sourceId: String!
    $destinationId: String!
    $amount: Float!
    $description: String
  ) {
    createNewEdge(
      ownerUid: $ownerUid
      sourceId: $sourceId
      destinationId: $destinationId
      amount: $amount
      description: $description
    ) {
      code
      success
      message
      edge {
        id
        sourceId
        destId
        amount
        description
      }
    }
  }
`

const GET_ALL_NODES = gql`
  query Nodes($ownerUid: ID!) {
    nodes(ownerUid: $ownerUid) {
      id
      name
    }
  }
`

const EdgeFormLogicHook = (attached_node_id, incoming, onSubmit) => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const newNodeOrEdge = useSelector((state) => state.graph.newNodeOrEdge)
  const { loading, error, data, refetch } = useQuery(GET_ALL_NODES, {
    variables: {
      ownerUid: currentUser.uid,
    },
  })
  const [newEdgeState, setNewEdgeState] = useState({
    targetNode: '',
    amount: '0',
    description: '',
  })
  const [newEdgeBool, setNewEdgeBool] = useState(false)
  const [createNewEdge] = useMutation(CREATE_NEW_EDGE, {
    variables: {
      sourceId: incoming ? newEdgeState.targetNode : attached_node_id,
      destinationId: incoming ? attached_node_id : newEdgeState.targetNode,
      amount: parseFloat(newEdgeState.amount),
      description: newEdgeState.description,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      setNewEdgeState({
        amount: '0',
        targetNode: '',
        description: '',
      })
      setNewEdgeBool(false)
      dispatch(announceChange('New Edge created'))
      logEvent(analytics, 'new_edge_created')
      onSubmit()
    },
  })
  const handleTargetNodeChange = (event) => {
    setNewEdgeState({
      targetNode: event.target.value,
      amount: newEdgeState.amount,
      description: newEdgeState.description,
    })
  }

  const handleNewEdgeAmountChange = (event) => {
    setNewEdgeState({
      targetNode: newEdgeState.targetNode,
      amount: event.target.value,
      description: newEdgeState.description,
    })
  }
  const handleNewEdgeDescriptionChange = (event) => {
    setNewEdgeState({
      amount: newEdgeState.amount,
      targetNode: newEdgeState.targetNode,
      description: event.target.value,
    })
  }

  function handleEdgeFormSubmit(event) {
    createNewEdge()
    event.preventDefault()
  }
  // Support inline node creation.
  const [newNodeMode, toggleNewNodeMode] = useState(false)
  function toggleNewNodeForm() {
    toggleNewNodeMode(!newNodeMode)
    refetch()
  }

  return {
    handleTargetNodeChange,
    handleNewEdgeAmountChange,
    handleNewEdgeDescriptionChange,
    data,
    newEdgeBool,
    setNewEdgeBool,
    newEdgeState,
    handleEdgeFormSubmit,
    newNodeMode,
    toggleNewNodeForm
  }
}

export default EdgeFormLogicHook
