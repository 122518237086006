import React, { useState, useEffect } from 'react'
import './ProfilePageStyle.css'
import ProfilePageLogicHook from './ProfilePageLogic'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'


const ProfilePageView = () => {
    const [isPersonalInfoEditMode, setIsPersonalInfoEditMode] = useState(false)
    const [isSecurityInfoEditMode, setIsSecurityInfoEditMode] = useState(false)
    
    const {
        editPersonalInfo, editSecurityInfo, handleLogout
    } = ProfilePageLogicHook()


    return (
        <div className='profilePage'>
            
            <h2>John Smith</h2>
            <div>
                <div className='titleIcon'>
                    <h3>Personal Information</h3>
                    <Button>
                        <EditIcon onClick={(e) => editPersonalInfo()}></EditIcon>
                    </Button>
                </div>
                <div className='infoRow'>
                    <div className='inputField' id='left'>
                    <InputLabel>First Name</InputLabel>
                    <TextField disabled={!isPersonalInfoEditMode} id="first-name" variant="outlined" defaultValue="John"/>
                    </div>
                    <div className='inputField'>
                    <InputLabel>Last Name</InputLabel>
                    <TextField disabled={!isPersonalInfoEditMode} id="last-name" variant="outlined" defaultValue="Smith"/>
                    </div>
                </div>
                <div className='infoRow'>
                    <div className='inputField' id='left'>
                    <InputLabel>Email</InputLabel>
                    <TextField disabled={!isPersonalInfoEditMode} id="email" variant="outlined" defaultValue="test@gmail.com"/>
                    </div>
                    <div className='inputField'>
                    <InputLabel>Phone</InputLabel>
                    <TextField disabled={!isPersonalInfoEditMode} id="phone" variant="outlined" defaultValue="720-453-8916"/>
                    </div>
                </div>
            </div>
            
            <div>
                <div className='titleIcon'>
                    <h3>Security</h3>
                    <Button>
                        <EditIcon onClick={(e) => editSecurityInfo()}></EditIcon>
                    </Button>
                </div>
                <div className='infoRow'>
                    <div className='inputField' id='left'>
                    <InputLabel>Password</InputLabel>
                    <TextField disabled={!isSecurityInfoEditMode} id="password" variant="outlined" defaultValue="e7kel!$hkLM"/>
                    </div>
                    <div className='inputField'>
                    <InputLabel>Account Recovery Email</InputLabel>
                    <TextField disabled={!isSecurityInfoEditMode} id="recovery-email" variant="outlined" defaultValue="johnsmith@gmail.com"/>
                    </div>
                </div>
                <Button variant="contained" id='logoutButton' onClick={(e) => handleLogout()}>Logout</Button>
            </div>
           
        </div>
    )
}

export default ProfilePageView
