import { gql, useQuery } from '@apollo/client'
import { useAuth } from '../../../contexts/AuthContext'

const GET_EDGE_QUERY = gql`
  query GET_EDGE_DETAIL($ownerUid: ID!, $edgeId: ID!, $nodeId: ID!) {
    edge(ownerUid: $ownerUid, id: $edgeId) {
      amount
      description
    }
    node(ownerUid: $ownerUid, id: $nodeId) {
      name
    }
  }
`

const NestedBudgetListItemLogic = (edgeId, referenceNodeID) => {
  const { currentUser } = useAuth()
  const { loading, error, data } = useQuery(GET_EDGE_QUERY, {
    variables: {
      ownerUid: currentUser.uid,
      edgeId: edgeId,
      nodeId: referenceNodeID,
    },
  })
  return { data }
}

const BudgetListItemLogic = () => {
  return {  }
}

export { BudgetListItemLogic, NestedBudgetListItemLogic }
