import './EditEdgeFormStyle.css'
import { EditEdgeFormLogicHook } from './EditEdgeFormLogic'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import calendar from '../../local_resources/calendar-solid.svg'
import Box from '@mui/material/Box'
import RecurringEdgeFormView from './RecurringEdgeForm/RecurringEdgeFormView'
import React from 'react'
import { FormControl, Typography } from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const EditEdgeFormView = (props) => {
  const edgeId = props.edgeId
  const referenceNodeID = props.nodeID // Tune based on incoming/outgoing
  const onSubmit = props.onSubmit
  const nested = props.nested

  const {
    data,
    loading,
    error,
    isHovered,
    setIsHovered,
    edgeAmount,
    setEdgeAmount,
    setEdgeDescription,
    edgeDescription,
    removeEdgeFromGraph,
    submitEdgeEdits,
    openDetail,
    handleCloseDetail,
    handleOpen,
    convertRruleToDate,
    refetch,
    setNewFocusNodeFunc,
    resetBalance
  } = EditEdgeFormLogicHook(edgeId, referenceNodeID, onSubmit)

  return (
    <li
      className="edge-as-li-style"
      key={edgeId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <form className="interactive-edge-form" onSubmit={submitEdgeEdits}>
        {/* Set up the appropriate title - multiple edges can go to the same node,
            the different names are set up as the Edge Description. 
        */}
        {!nested ? (
          <div className='name-section'>
            <h4 className="edge-name-label">{data?.node?.name}</h4>
            <span style={{ marginLeft: '1em' }}>{data?.edge?.description}</span>
          </div>
        ) : (
          <div className='name-section'>
            <TextField
              variant="outlined"
              sx={{marginLeft: '3vw'}}
              onChange={(e) => {
                setEdgeDescription(e.target.value)
              }}
              inputProps={{ inputMode: 'text' }}
              value={edgeDescription}
            ></TextField>
          </div>
        )}
        {/* Edge Editable Details  */}
        <div className='schedule-section'>
          <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {/* Edge Recurrence Details */}
            <Typography variant="h6" color="text.secondary">
              {data?.edge?.rrule ? convertRruleToDate(data?.edge?.rrule) : 'none'}
            </Typography>
            {isHovered ? (
                <div className='calendar-edit'>
                  <Button onClick={handleOpen} sx={{width: '50%'}}>
                    <img src={calendar} alt="calendar" className="calendar-icon" />
                  </Button>
                </div>
            ) : (
              <></>
            )}
          </FormControl>
        </div>
        {/* Edge Amount Details and confirm buttons */}
        <div className='amount-section'>
          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '100%',
            }}
          >
            <div className='edge-balance'>
              <TextField
                variant="outlined"
                onChange={(e) => {
                  setEdgeAmount(e.target.value)
                  console.log('state ', e.target.value)
                  console.log('data ', data?.edge.amount)
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={edgeAmount}
                style={{ minHeight: '50%' }}
              ></TextField>
            </div>

            {/* Confirm Any changes, if any have been made */}
            {edgeAmount === data?.edge.amount &&
            edgeDescription === data?.edge.description ? (
              <div className='confirm-button'></div>
            ) : (
              <div className='confirm-button'>
                <RefreshIcon onClick={() => resetBalance()}
                />
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  style={{ backgroundColor: 'green' }}
                >
                  ✓
                </Button>
              </div>
            )}
            </FormControl>
        </div>
          {/* Edge Action Button Button */}
          {isHovered ? (
            <div className="edge-button-section">
              <Tooltip title="Delete Edge">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  style={{ backgroundColor: 'grey' }}
                  onClick={removeEdgeFromGraph}
                >
                  x
                </Button>
              </Tooltip>
              <Tooltip title="Inspect Node in Detail">
                <IconButton onClick={() => {
                  setNewFocusNodeFunc(referenceNodeID)
                }} style={{
                  marginLeft: '1rem',
                  backgroundColor: 'blue',
                  color: 'white',
                }}>
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <></>
            // <div className="edge-button-section">
            //   <></>
            // </div>
          )}
          {/* Modal for Recurring Edge Details when opened by calendar*/}
          <Modal
            open={openDetail}
            onClose={handleCloseDetail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <RecurringEdgeFormView
                edgeId={edgeId}
                handleCloseDetail={handleCloseDetail}
                calendarFetch={refetch}
              />
            </Box>
          </Modal>
      </form>
    </li>
  )
}

export default EditEdgeFormView
