import React from 'react'
import Typography from '@mui/material/Typography'
import {
  Link,
  Grid,
  Box,
  Alert,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material'
import { SignInFormLogic } from './SignInFormLogic'
import logo from '../../../bucket-logo-purple.png'

export default function SignInFormView(props) {
  const { handleSubmit, error, remember, setRemember } = SignInFormLogic()
  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" style={{ maxWidth: '5vw', alignItems: 'center', marginBottom: '.5vw' }}/>
        <h1>Welcome Back</h1>
        <Grid container justifyContent="center" sx={{ gap: 2 }}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Link href="/signup" variant="h5" sx={{ color: 'grey.500', textDecoration: 'none', '&:hover': { color: 'grey.700' } }}>
            {"Sign Up"}
          </Link>
        </Grid>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            checked={remember}
            onChange={(e) => {
              setRemember(e.target.checked)
            }}
            id="remember"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
