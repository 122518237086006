import { gql, useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { announceChange } from '../../slices/graphSlice'
import { useDispatch } from 'react-redux'

const ADD_HISTORICAL_DATA = gql`
  mutation addHistoricalData(
    $ownerUid: ID!
    $nodeId: ID!
    $date: String!
    $balance: Float!
  ) {
    addHistoricalData(
      ownerUid: $ownerUid
      nodeId: $nodeId
      date: $date
      balance: $balance
    ) {
      code
      message
      success
    }
  }
`

const NodeHistoricalValueFormLogicHook = (closeModal) => {
  const { currentUser } = useAuth()
  const focus_node_id_redux = useSelector((state) => state.graph.focus_node_id)
  const [date, setDate] = useState('')
  const [balance, setBalance] = useState(0)
  const dispatch = useDispatch()

  const [addHistoricalData] = useMutation(ADD_HISTORICAL_DATA, {
    variables: {
      nodeId: focus_node_id_redux,
      ownerUid: currentUser.uid,
      date: date,
      balance: parseFloat(balance),
    },
    onCompleted: (data) => {
      console.log('Mutation completed- new node historical data ', data)
      dispatch(announceChange('New node delivery'))
      setDate('')
      setBalance(0)
      closeModal()
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    addHistoricalData()
  }

  return {
    date,
    setDate,
    balance,
    setBalance,
    handleSubmit,
  }
}

export default NodeHistoricalValueFormLogicHook
