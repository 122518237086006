import { useAuth } from '../../../contexts/AuthContext'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UpdateAuthFormLogic = () => {
  const { currentUser, updateAccountEmail, updateAccountPassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    const passwordConfirm = data.get('password-confirm')
    if (password !== passwordConfirm) {
      return setError('Passwords do not match.')
    }
    setLoading(true)
    setError('')

    const promises = []
    if (email !== currentUser.email) {
      promises.push(updateAccountEmail(email))
    }
    if (password) {
      promises.push(updateAccountPassword(password))
    }
    Promise.all(promises)
      .then(() => {
        navigate('/')
      })
      .catch(() => {
        setError('Failed to update Account')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function retraceStep() {
    navigate(-1)
  }

  return {
    currentUser,
    handleSubmit,
    retraceStep,
    error,
    loading,
  }
}
export { UpdateAuthFormLogic }
