import './WebHeaderStyle.css'
import WebHeaderLogicHook from './WebHeaderLogic'
import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { NavLink } from 'react-router-dom'
import logo from '../../bucket-logo-white.png'
import userSolidIcon from '../../local_resources/user-solid.svg'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import Menu from '@mui/material/Menu'

function WebHeader(props) {
  const { handleLogout, viewProfile, viewAccounts } = WebHeaderLogicHook()

  return (
    <header className="Header">
      <NavLink to="/" className="Logo">
        <img className="webHeaderIcon" src={logo} alt="" />
        <span className="homeLogoText">Bucket Financials</span>
      </NavLink>
      <div className="Nav">
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button variant="contained" {...bindTrigger(popupState)}>
                <img className="navIcon" src={userSolidIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={viewProfile}>View Profile</MenuItem>
                <MenuItem onClick={viewAccounts}>Accounts</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </div>
    </header>
  )
}

// <div className='header-div-styling'>
//     <h1>Welcome to your financial health {currentUser?.email}</h1>
//     <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//     {/** Code stub for future use toggling across graphs. Today each user has only one graph*/}
//     {/*
//     <InputLabel id="demo-select-small">Available Graphs</InputLabel>
//     <Select
//             labelId="demo-simple-select-label-small"
//             id="demo-simple-select-small"
//             label="Available Graphs"
//             value={graph_id}
//             onChange={changeDisplayedGraph}
//             style={{minWidth: "40%", textDecorationColor: "black"}}
//         >
//             {
//             Object.keys(available_graphs).map((key, i) => {
//                 const val = available_graphs[key].graph_id
//                 return (
//                 <MenuItem key={val} value={val}>{val}</MenuItem>
//                 )
//             })
//             }
//     </Select> */}
//     {(unsavedChanges) ?
//         <Button variant="outlined" onClick={SaveGraph}>
//         Publish Changes.
//         </Button>:
//         <></>
//     }
//     </FormControl>
//     <MenuListView
//         viewProfile={viewProfile}
//         handleLogout={handleLogout}
//     />
//     </div>

export default WebHeader
