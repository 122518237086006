import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'


const ProfilePageLogicHook = () => {
    
    let navigate = useNavigate()
    const { logout } = useAuth()

    function editPersonalInfo(){
        alert("Updating personal information isn't available yet!")
    }

    function editSecurityInfo(){
        navigate('/update-account')
    }

    async function handleLogout() {
        try {
            await logout()
            navigate('/login')
        } catch (err){
            console.log(err)
        }
        console.log('Successfully logged out.')
    }

    return {
        editPersonalInfo, editSecurityInfo, handleLogout
    } 
}

export default ProfilePageLogicHook