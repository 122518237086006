import './NewEdgeFormStyle.css'
import EdgeFormLogicHook from './NewEdgeFormLogic'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { InputLabel, Menu } from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import NewNodeForm from '../NewNodeForm/NewNodeFormView'

const nodeFormStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const NewEdgeFormView = (props) => {
  const node_id = props.node_id
  const incoming = props.incoming
  const onSubmit = props.onSubmit

  const {
    handleTargetNodeChange,
    handleNewEdgeAmountChange,
    handleNewEdgeDescriptionChange,
    data,
    newEdgeBool,
    setNewEdgeBool,
    newEdgeState,
    handleEdgeFormSubmit,
    newNodeMode,
    toggleNewNodeForm
  } = EdgeFormLogicHook(node_id, incoming, onSubmit)

  if (newEdgeBool) {
    return data === null || data === undefined ? (
      <h1>Loading</h1>
    ) : (
      <div className="form-style">
        <form onSubmit={handleEdgeFormSubmit} style={{minWidth: '90%'}}>
          <div className='details'>
            <div className='pair'>
              <InputLabel id="income-edge-label">Associated Node</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newEdgeState.targetNode}
                label="Income Edge"
                onChange={handleTargetNodeChange}
              >
                {Object.keys(data?.nodes).map((key, i) => {
                  const val = data.nodes[key].name
                  return (
                    <MenuItem key={data.nodes[key].id} value={data.nodes[key].id}>
                      {val}
                    </MenuItem>
                  )
                })}
                <MenuItem
                  value={-1}
                  onClick={() => {
                    toggleNewNodeForm()
                  }}
                  style={{ 
                    backgroundColor: 'lightgrey',
                    color: 'purple' }}>+ New Node</MenuItem>
              </Select>
            </div>
            <div className='pair'>
              <InputLabel id="income-edge-label">Amount (USD)</InputLabel>
              <TextField
                variant="outlined"
                onChange={handleNewEdgeAmountChange}
                inputProps={{ type: 'number', step: '0.01', min: '0' }}
                value={newEdgeState.amount}
              ></TextField>
            </div>
            <div className='pair'>
              <InputLabel id="income-edge-label">Description</InputLabel>
              <TextField
                variant="outlined"
                onChange={handleNewEdgeDescriptionChange}
                inputProps={{ inputMode: 'text' }}
                value={newEdgeState.description}
              ></TextField>
            </div>
          </div>
          <div className='buttons'>
            <Button variant="contained" type="submit" size="small">
              +
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setNewEdgeBool(false)
              }}
              size="small"
            >
              Cancel
            </Button>
          </div>
        </form>
        <Modal
          open={newNodeMode}
          onClose={toggleNewNodeForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={nodeFormStyle}>
            <h1>New Node</h1>
            <NewNodeForm toggleNewNodeForm={toggleNewNodeForm} />
          </Box>
        </Modal>
      </div>
    )
  } else {
    return (
      <div style={{ maxHeight: '10%' }}>
        <Button
          variant="outlined"
          onClick={() => {
            setNewEdgeBool(true)
          }}
          size="small"
        >
          Add new Edge
        </Button>
      </div>
    )
  }
}

export default NewEdgeFormView
