import { createSlice } from '@reduxjs/toolkit'
import blankGraph from '../cash_graph/blank.js'
import complexUserGraph from '../cash_graph/complex_user.js'
import mockUserGraph from '../cash_graph/mock_user.js'

const default_user_id = 'DefaultUser'
const DEFAULT_NODE_TYPES = [
  'Asset',
  'Liability',
  'Income Source',
  'Expense',
  'External User',
  'Other',
]
const AVAILABLE_GRAPHS = {}
AVAILABLE_GRAPHS[blankGraph.graph_id] = blankGraph
AVAILABLE_GRAPHS[mockUserGraph.graph_id] = mockUserGraph
AVAILABLE_GRAPHS[complexUserGraph.graph_id] = complexUserGraph

const today = new Date()
const defaultStartDate = new Date()
defaultStartDate.setUTCMonth(today.getUTCMonth() - 3)
const defaultEndDate = new Date()
defaultEndDate.setUTCMonth(defaultEndDate.getUTCMonth() + 3)

export const graphSlice = createSlice({
  name: 'graph',
  initialState: {
    available_graphs: [],
    graph_id: default_user_id,
    node_types: DEFAULT_NODE_TYPES,
    unsavedChanges: false,
    focus_node_id: '',
    viewGraph: true,
    newNodeOrEdge: false,
    newTransaction: false,
    startDate: defaultStartDate.toISOString(),
    endDate: defaultEndDate.toISOString()
  },
  reducers: {
    setGraph: (state, action) => {
      const new_graph_id = action.payload
      state.graph_id = new_graph_id
    },
    setFocusNode: (state, action) => {
      const node_id = action.payload
      console.log(node_id)
      state.focus_node_id = node_id
    },
    setSavedChanges: (state, action) => {
      state.unsavedChanges = false
    },
    setViewGraphMode(state, action) {
      state.viewGraph = action.payload
    },
    announceChange(state, action) {
      state.newNodeOrEdge = !state.newNodeOrEdge
    },
    announceNewTransaction(state, action) {
      state.newTransaction = !state.newTransaction
    },
    /**
     * Sets the start date of the time span to be inspected.
     * Should be an ISO String.
     */
    setStartDate(state, action) {
      state.startDate = action.payload
    },
    /**
     * Sets the end date of the time span to be inspected.
     * Should be an ISO String.
     */
    setEndDate(state, action) {
      state.endDate = action.payload
    }
  },
})

export const {
  setGraph,
  setSavedChanges,
  setFocusNode,
  setViewGraphMode,
  announceChange,
  announceNewTransaction,
  setStartDate,
  setEndDate
} = graphSlice.actions
export default graphSlice.reducer
