import './App.css'
import React, { useState, useEffect } from 'react'

import { Provider } from 'react-redux'
import store from './store'
import WebHeader from './components/WebHeader/WebHeaderView'
import {AuthLoginView, AuthSignUpView, AuthResetPasswordView} from './components/Auth/AuthView'
import { AuthProvider } from './contexts/AuthContext'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'
import UpdateAuthFormView from './components/Auth/UpdateAuthForm/UpdateAuthFormView'
import PlaygroundView from './pages/PlaygroundView/PlaygroundView'
import AccountsView from './pages/AccountsPage/AccountsPageView'
import ProfileView from './pages/ProfilePage/ProfilePageView'
import NodeFullDetailView from './pages/NodeFullDetailView/NodeFullDetailView'

function App() {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  /** Render the app. */
  if (isLoading) {
    return <div className="App">Loading..</div>
  }
  return (
    <div className="fullScreenView">
      <WebHeader />
      <div className="mainContainer">
        <PlaygroundView />
      </div>
    </div>
  )
}
function AccountPage() {
  return (
    <div className="fullScreenView">
      <WebHeader />
      <div className="mainContainer">
        <AccountsView />
      </div>
    </div>
  )
}

//TODO(mjones): Work on this for the node page
function NodePage() {
  return (
    <div className="fullScreenView">
      <WebHeader />
      <div className="mainContainer">
        <NodeFullDetailView />
      </div>
    </div>
  )
}

function ProfilePage() {
  return (
    <div className='fullScreenView'>
      <WebHeader />
      <div className="mainContainer">
        <ProfileView />
      </div>
    </div>
  )
  
}

function PrivateRoute() {
  const { currentUser } = useAuth()
  return currentUser ? <App /> : <Navigate to="/login" />
}

class AppWrapper extends React.Component {
  render() {
    return (
      <AuthProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<PrivateRoute/>} />
              <Route path="/nodes/:nodeId" component={<NodePage/>} />
              <Route exact path="/accounts" element={<AccountPage/>} />
              <Route path="/signup" element={<AuthSignUpView/>} />
              <Route path="/login" element={<AuthLoginView/>} />
              <Route path="/reset-password" element={<AuthResetPasswordView/>} />
              <Route path="/update-account" element={<UpdateAuthFormView/>} />
              <Route path="/profile" element={<ProfilePage/>} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </AuthProvider>
    )
  }
}

export default AppWrapper
