import { useAuth } from '../../../contexts/AuthContext'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { analytics } from '../../../firebase'
import { logEvent } from 'firebase/analytics'

const SignInFormLogic = () => {
  const { login, currentUser } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      navigate('/')
    }
  }, [currentUser])

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    try {
      setError('')
      await login(email, password, remember)
    } catch (err) {
      console.log(err)
      setError('Failed to log in to the account')
      logEvent(analytics, 'login_failure')
      return
    }
    setLoading(false)
    logEvent(analytics, 'login_success')
    navigate('/')
  }

  return {
    handleSubmit,
    error,
    loading,
    remember,
    setRemember,
  }
}
export { SignInFormLogic }
