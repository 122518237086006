import './NodeFullDetailViewStyle.css'
import NodeFullDetailLogicHook from './NodeFullDetailLogic'
import NodeBalanceView from '../../components/NodeBalanceView/NodeBalanceView'
import NodeBudgetView from '../../components/NodeBudgetView/NodeBudgetView'
import NodeHistoryFormView from '../../components/NodeHistoryForm/NodeHistoryFormView'
import Paper from '@mui/material/Paper'
import exit from '../../local_resources/circle-xmark-solid.svg'
import { Fragment } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Button, FormControl, Modal, Select, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import UserAccountDisplayView from '../../components/UserAccountDisplay/UserAccountDisplayView'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TimelineCalView from '../../components/TimelineCalView/TimelineCalView'
import { TextField, InputLabel, MenuItem} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const AccountsTab = ({
  data,
  openAccount,
  handleOpenAccounts,
  handleCloseAccounts,
  setSelectedAccounts,
  attachAccountsToNode,
}) => {
  return (
    <List>
      {data.node.accounts.map((account, _) => {
        return (
          <ListItemButton
            key={account.id}
            className="list-item-button"
            onClick={() => {}}
          >
            <ListItemText primary={account.name} />
            {/* <img src={exit} /> */}
          </ListItemButton>
        )
      })}
      <div className="list-item-button-add-account">
        <ListItemButton onClick={handleOpenAccounts}>
          <ListItemText primary="Add Account" />
        </ListItemButton>
        <Modal
          open={openAccount}
          onClose={handleCloseAccounts}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <h1>Accounts</h1>
            <h3>Select Accounts to Attach to Node</h3>
            <UserAccountDisplayView
              showSelector={true}
              parentSetSelectedAccounts={setSelectedAccounts}
            />
            <Button onClick={attachAccountsToNode}>
              Attach Selected Accounts
            </Button>
          </Box>
        </Modal>
      </div>
    </List>
  )
}

const NodeFullDetailView = (props) => {
  const {
    loading,
    error,
    data,
    openAccount,
    handleOpenAccounts,
    handleCloseAccounts,
    childAccountSelector,
    setSelectedAccounts,
    attachAccountsToNode,
    value,
    handleChange,
    localNote,
    setLocalNote,
    editNode,
    categories,
    localType,
    setLocalType,
    refreshType
  } = NodeFullDetailLogicHook()
  const setGraphView = props.setGraphView

  if (loading || !data) return <h1>Loading</h1>
  // TODO(mjones) temporary style fix on container for cloud deployment. Issue with CSS?
  // Ongoing issue, fixed with inline style for now.
  return (
    <section className="node-detail-grid" style={{display: 'grid', maxHeight: '100%'}}> 
      <div className="grid-item-node-header">
        <div className="header-left">
          <h1>{data.node.name}</h1>
          <FormControl variant='outlined' 
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <InputLabel>Node Type</InputLabel>
            <Select 
              id='type-category-select'
              label='Category'
              value={localType}
              onChange={(e) => setLocalType(e.target.value)}
            >
              {categories.map((category, _) => {
                return (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                )
              })}
            </Select>
            {localType !== data.node.type ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}
              >
                <Tooltip title='Reset'>
                  <RefreshIcon onClick={refreshType}/>
                </Tooltip>
                <Tooltip title='Update type'>
                  <CheckCircleIcon 
                    onClick={() => {
                      editNode()
                    }}
                  />
                </Tooltip>
              </div>
            ) :(
              <></>
            )}
          </FormControl>
        </div>
        <div className="header-right">
          <img
            className="exit-icon"
            onClick={() => {
              setGraphView(true)
            }}
            src={exit}
          />
          <TimelineCalView />
        </div> 
      </div>
      <div className="grid-item-node-graph">
        <NodeBalanceView />
      </div>
      <div className="grid-item-node-tab">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Budget" value="1" />
              <Tab label="Value History" value="2" />
              <Tab label="Accounts" value="3" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <NodeBudgetView />
          </TabPanel>
        <TabPanel value="2">
            <NodeHistoryFormView />
          </TabPanel>
          <TabPanel value="3">
            <AccountsTab
              data={data}
              openAccount={openAccount}
              handleOpenAccounts={handleOpenAccounts}
              handleCloseAccounts={handleCloseAccounts}
              setSelectedAccounts={setSelectedAccounts}
              attachAccountsToNode={attachAccountsToNode}
            />
          </TabPanel>
        </TabContext>
      </div>
      <div className="grid-item-node-insights">
        <h4>Node Notes</h4>
        <TextField
          id="notepad-field"
          label="Notepad"
          multiline
          rows={10}
          variant="outlined"
          sx={{ width: '100%' }}
          value={localNote}
          onChange={(e) => setLocalNote(e.target.value)}
          placeholder='Enter notes here...'
        />
        {localNote !== data?.node.note ? 
          <Button
            onClick={editNode}
            style={{ borderRadius: '4px', backgroundColor: 'blue', textDecorationColor: 'white', color: 'white', marginTop: '10px' }}
          >
            Update Note
          </Button> : <></>}
        {/* <Paper elevation={3}>
          <h1>Insights</h1>
          <Fragment>
            <body>Nothing at this time..</body>
          </Fragment>
        </Paper> */}
      </div>
    </section>
  )
}
export default NodeFullDetailView
