import { gql, useQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useDispatch } from 'react-redux'
import { announceNewTransaction } from '../../slices/graphSlice'

const ADD_TRANSACTION = gql`
  mutation CreateNewTransaction(
    $ownerUid: ID!
    $amount: Float!
    $date: String!
    $currencyCode: String!
    $vendor: String!
    $edgeId: ID
  ) {
    createNewTransaction(
      ownerUid: $ownerUid
      amount: $amount
      date: $date
      currencyCode: $currencyCode
      vendor: $vendor
      edgeId: $edgeId
    ) {
      code
      success
      message
      transaction {
        id
        ownerUid
        amount
        date
        currencyCode
        vendor
        network
        edgeId
        description
      }
    }
  }
`

const GET_EDGES = gql`
  query GetEdges($ownerUid: ID!) {
    edges(ownerUid: $ownerUid) {
      id
      description
    }
  }
`

const TransactionFormLogicHook = (closeModal) => {
  const dispatch = useDispatch()

  const { currentUser } = useAuth()
  const [amount, setAmount] = useState(0)
  const [date, setDate] = useState(new Date())
  const [currencyCode, setCurrencyCode] = useState('USD')
  const [vendor, setVendor] = useState('')
  const [network, setNetwork] = useState('ACH')
  const [edgeId, setEdgeId] = useState('')
  const [description, setDescription] = useState('')

  const [createNewTransaction] = useMutation(ADD_TRANSACTION, {
    variables: {
      ownerUid: currentUser.uid,
      amount: parseFloat(amount),
      date: date,
      currencyCode: currencyCode,
      vendor: vendor,
      network: network,
      edgeId: edgeId,
      description: description,
    },
    onCompleted: (data) => {
      console.log('Mutation completed- new transaction ', data)
      closeModal()
      dispatch(announceNewTransaction('New transaction submitted'))
    },
  })
  const { loading, error, data, refetch } = useQuery(GET_EDGES, {
    variables: {
      ownerUid: currentUser.uid,
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    createNewTransaction()
  }

  return {
    amount,
    setAmount,
    date,
    setDate,
    currencyCode,
    setCurrencyCode,
    vendor,
    setVendor,
    network,
    setNetwork,
    edgeId,
    setEdgeId,
    description,
    setDescription,
    handleSubmit,
    data,
  }
}

export { TransactionFormLogicHook }
