import { TextField, Button, Grid } from '@mui/material'
import NodeHistoricalValueFormLogicHook from './NodeHistoricalValueFormLogic'

const NodeHistoricalValueFormView = ({closeModal}) => {
  const {
    date,
    setDate,
    balance,
    setBalance,
    handleSubmit,
  } = NodeHistoricalValueFormLogicHook(closeModal)

  return (
    <form onSubmit={handleSubmit}>
      <h1>Update historical balance</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="date"
            label="Date"
            type="date"
            name="date"
            autoComplete="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="balance"
            label="Balance"
            type="number"
            name="balance"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NodeHistoricalValueFormView
