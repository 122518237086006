import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link'
import { useAuth } from '../../contexts/AuthContext'
import { gql, useQuery } from '@apollo/client'

const GET_ACCOUNT_QUERY = gql`
  query GET_ACCOUNTS($ownerUid: ID!) {
    accounts(ownerUid: $ownerUid) {
      id
      name
      currentBalance
      type
    }
  }
`

const GET_NODES = gql`
    query GetNodes($ownerUid: ID!) {
        nodes(ownerUid: $ownerUid) {
            id
            name
            accounts{
                id
            }
        }
    }
`


const UserAccountDisplayLogic = (parentSetSelectedAccounts) => {
const [nodeData, setNodeData] = useState([])  
const { currentUser } = useAuth()
  const { loading, error, data, refetch } = useQuery(GET_ACCOUNT_QUERY, {
    variables: {
      ownerUid: currentUser ? currentUser.uid : '',
    },
    onCompleted: (data) => {
      console.log('Accounts query completed: ', data)
    },
  })
  const [linkToken, setLinkToken] = useState('')
  const [parentAccountSelection, setParentAccountSelection] = useState(
    new Set()
  )

  const onSuccess = (public_token) => {
    // log and save the metadata
    // send the public_token to your app server
    // The Plaid API server will exchange the public_token for an access_token
    // The access_token can be used to make Plaid API calls to the user's accounts
    // https://plaid.com/docs/#exchange-token-flow
    const response = fetch(
      'http://localhost:3000/plaid/exchange_public_token',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          public_token: public_token,
          client_user_id: currentUser.uid,
        }),
      }
    )
    response.json().then((json) => {
      console.log('Response to exchange public token: ', json)
    })
  }

  const config = {
    onSuccess: onSuccess,
    onExit: (err, metadata) => {
      console.log(err, metadata)
    },
    onEvent: (eventName, metadata) => {
      console.log(eventName, metadata)
    },
    token: linkToken,
    // required for OAuth if not using OAuth, set to null or omit:
    receivedRedirectUri: null,
  }

  const { open, exit, ready } = usePlaidLink(config)
  const generateToken = async () => {
    // TODO(mjones): configure to all environments with env variables
    fetch('http://localhost:3000/plaid/create_link_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          client_user_id: 'random user',
        },
      }),
    }).then((response) => {
      response.json().then((json) => {
        console.log('Response to create link token: ', json)
        setLinkToken(json.link_token)
      })
    })
  }
  const { nodeLoading, nodeError, nodeDatas, nodeRefetch } = useQuery(GET_NODES, {
      variables: {
          "ownerUid": currentUser ? currentUser.uid : '',
      },
      onCompleted: (data) => {
          console.log('nodes query completed: ', data)
          setNodeData(data.nodes)
          console.log('setting node data', nodeData)
      },
  })

  const accountSelectedFunction = (e, accountId) => {
    if (parentAccountSelection.has(accountId)) {
      parentAccountSelection.delete(accountId)
    } else {
      parentAccountSelection.add(accountId)
    }
    setParentAccountSelection(parentAccountSelection)
    parentSetSelectedAccounts(parentAccountSelection)
  }

  const numberOfNodesPerAccount = (accountId) => {
      let count = 0
      
      nodeData?.forEach((node) => {
          let accounts = node.accounts
          accounts?.forEach((account) => {
              if (account.id === accountId){
                count = count++
              }
          })
      })
      return count
  }

  useEffect(() => {
      generateToken()
      refetch()
  }, [currentUser])
  const newNodeOrEdge = useSelector((state)=>state.graph.newNodeOrEdge)
  useEffect(()=>{
      refetch()
  }, [newNodeOrEdge])

  return {
      data,
      open,
      exit,
      ready,
      linkToken,
      accountSelectedFunction,
      numberOfNodesPerAccount
  }
}

export default UserAccountDisplayLogic
