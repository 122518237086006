import { useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useAuth } from '../../contexts/AuthContext'
import { announceChange } from '../../slices/graphSlice'
import { useDispatch } from 'react-redux'
import { rrulestr, RRule } from 'rrule';
 import { setFocusNode } from '../../slices/graphSlice'
// TODO(mjones): Extend to modify the source or destination IDs as well.
const EDIT_EDGE_MUTATION = gql`
  mutation EditEdge(
    $ownerUid: ID!
    $edgeId: ID!
    $amount: Float
    $description: String
  ) {
    editEdge(
      ownerUid: $ownerUid
      edgeId: $edgeId
      amount: $amount
      description: $description
    ) {
      code
      success
      message
      edge {
        id
        sourceId
        destId
        amount
        description
      }
    }
  }
`

const REMOVE_EDGE_MUTATION = gql`
  mutation RemoveEdge($ownerUid: ID!, $edgeId: ID!) {
    removeEdge(ownerUid: $ownerUid, edgeId: $edgeId) {
      code
      success
      message
    }
  }
`

const GET_EDGES_QUERY = gql`
  query GET_EDGE_DETAIL($ownerUid: ID!, $edgeId: ID!, $nodeId: ID!) {
    edge(ownerUid: $ownerUid, id: $edgeId) {
      amount
      description
      rrule
    }
    node(ownerUid: $ownerUid, id: $nodeId) {
      name
    }
  }
`

const EditEdgeFormLogicHook = (edgeId, referenceNodeID, onSubmit) => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const [isHovered, setIsHovered] = useState(false)
  const [edgeAmount, setEdgeAmount] = useState(0)
  const [edgeDescription, setEdgeDescription] = useState('')
  const { loading, error, data, refetch } = useQuery(GET_EDGES_QUERY, {
    variables: {
      ownerUid: currentUser.uid,
      edgeId: edgeId,
      nodeId: referenceNodeID,
    },
    onCompleted: (data) => {
      setEdgeAmount(data.edge.amount)
      setEdgeDescription(data.edge.description)
    },
  })
  useEffect(() => {
    if (data) {
      setEdgeAmount(data.edge.amount)
    }
  }, [data])

  /**
   * Enable reseting the balance to the fetched value
   */
  function resetBalance() {
    if (data) {
      setEdgeAmount(data.edge.amount)
    } else {
      setEdgeAmount(0)
    }
  }

  const [editEdge] = useMutation(EDIT_EDGE_MUTATION, {
    variables: {
      edgeId: edgeId,
      amount: parseFloat(edgeAmount),
      ownerUid: currentUser.uid,
      description: edgeDescription,
    },
    onCompleted: (data) => {
      setEdgeAmount(data.editEdge.edge.amount)
      setEdgeDescription(data.editEdge.edge.description)
      refetch()
      dispatch(announceChange(''))
      onSubmit()
    },
  })
  /** Wrapper function for edit mutation to prevent event default, as well as update the parent, */
  const submitEdgeEdits = (event) => {
    event.preventDefault()
    editEdge()
  }

  const [removeEdge] = useMutation(REMOVE_EDGE_MUTATION, {
    variables: {
      edgeId: edgeId,
      ownerUid: currentUser.uid,
    },
    onCompleted: (data) => {
      dispatch(announceChange(''))
      onSubmit()
    },
  })

  /** Removes an edge form the graph */
  function removeEdgeFromGraph(event) {
    event.preventDefault()
    removeEdge()
  }

  // Handle the detail view
  const [openDetail, setOpenDetail] = useState(false)
  const handleCloseDetail = () => {
    setOpenDetail(false)
  }
  const handleOpen = () => {
    setOpenDetail(true)
  }

  /**
   * Converts an RRule string to a human-readable date format.
   *
   * @param {string|null} rrule - The RRule string to convert.
   * @returns {string|null} The human-readable date format, or null if rrule is null.
   */
  function convertRruleToDate(rrule) {
    const rruleObj = rrulestr(rrule);
    if (rruleObj.origOptions.freq === RRule.YEARLY && rruleObj.origOptions.count === 1) {
      return 'one time on - ' + new Date(rruleObj.origOptions.dtstart).toLocaleDateString();
    } else {
      return rruleObj.toText();
    }
  }

  /**
   * Allow navigation between nodes in the detail view.
   * @param {*} node_id 
   */
  function setNewFocusNodeFunc(node_id) {
    dispatch(setFocusNode(node_id))
  }

  return {
    data,
    loading,
    error,
    isHovered,
    setIsHovered,
    edgeAmount,
    setEdgeAmount,
    setEdgeDescription,
    edgeDescription,
    removeEdgeFromGraph,
    submitEdgeEdits,
    openDetail,
    handleCloseDetail,
    handleOpen,
    convertRruleToDate,
    refetch,
    setNewFocusNodeFunc,
    resetBalance
  }
}

export { EditEdgeFormLogicHook }
