import { useAuth } from '../../../contexts/AuthContext'
import { useState } from 'react'

const ResetPasswordFormLogic = () => {
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    try {
      setError('')
      await resetPassword(email)
      setMessage('Check your inbox for instructions to reset your account.')
    } catch (err) {
      console.log(err)
      setError('Failed to reseytpassword')
    }
    setLoading(false)
  }

  return {
    handleSubmit,
    error,
    loading,
    message,
  }
}
export { ResetPasswordFormLogic }
