import './NodeBudgetViewStyle.css'
import NodeBudgetLogicHook from './NodeBudgetLogic'
import BudgetListView from './BudgetList/BudgetListView'
import NewEdgeFormView from '../NewEdgeForm/NewEdgeFormView'
import { InputLabel } from '@mui/material'

const NodeBudgetView = (props) => {
  const {
    loading_edges,
    error_edges,
    focus_node_id_redux,
    fetchedIncomingEdges,
    fetchedOutgoingEdges,
    get_node_groupings,
    refetch,
  } = NodeBudgetLogicHook()
  if (loading_edges) return <h1>Loading...</h1>
  if (error_edges) return <h1>Error!</h1>

  const debit_groups = get_node_groupings(fetchedIncomingEdges, true)
  const credit_groups = get_node_groupings(fetchedOutgoingEdges, false)

  return (
    <div className="budget-view-style">
      <div className="budget-view-column-style">
        <div className='budget-table-header' style={{backgroundColor: "#75DC13"}}>
          <h3 className='budget-table-header-title'>Money In</h3>
          <h4 id="schedule-header-label" >Schedule</h4>
          <h4 id="balance-header-label" >Amount</h4>
          <h4 id="balance-header-label" style={{marginRight: '1rem'}}>Actions</h4>
        </div>
        <BudgetListView
          node_groupings={debit_groups}
          incoming={true}
          onSubmit={refetch}
        />
        <NewEdgeFormView
          node_id={focus_node_id_redux}
          incoming={true}
          onSubmit={refetch}
        />
      </div>
      <div className="budget-view-column-style">
        <div className='budget-table-header' style={{backgroundColor: "#FF6767"}}>
          <h3 className='budget-table-header-title'>Money Out</h3>
          <h4 id="schedule-header-label">Schedule</h4>
          <h4 id="balance-header-label" >Amount</h4>
          <h4 id="balance-header-label" style={{marginRight: '1rem'}}>Actions</h4>
        </div>
        <BudgetListView
          node_groupings={credit_groups}
          incoming={false}
          onSubmit={refetch}
        />
        <NewEdgeFormView
          node_id={focus_node_id_redux}
          incoming={false}
          onSubmit={refetch}
        />
      </div>
    </div>
  )
}

export default NodeBudgetView
