import './NodeInspectViewStyle.css'
import NodeInspectViewLogicHook from './NodeInspectViewLogic'

import NodeBudgetView from '../NodeBudgetView/NodeBudgetView'
import NodeTransactionView from '../NodeTransactionView/NodeTransactionView'
import { Button, TextField } from '@mui/material'
import dropdown from '../../local_resources/circle-dropdown-solid.svg'
import dropup from '../../local_resources/circle-dropup-solid.svg'

const NodeInspectView = (props) => {
  const {
    loading,
    error,
    data,
    nodeName,
    setNodeName,
    EditNodeWrapper,
    removeNode,
    currentBalance,
    setCurrentBalance,
  } = NodeInspectViewLogicHook()

  if (loading === true) return <h1>Loading</h1>
  if (error === true) return <h1>Error</h1>
  const setGraphView = props.setGraphView
  const graphView = props.graphView

  return (
    <div className="inspect-component-div">
      {data?.node === null ? (
        <h1>Select a node</h1>
      ) : (
        // Display some high level node info when one is selected
        <div className="full-node-info-div">
          <div className="full-node-info-header-div">
            <form
              className="full-node-interactive-node-name-form"
              onSubmit={EditNodeWrapper}
            >
              <div className="node-metadata">
                <TextField
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    setNodeName(e.target.value)
                  }}
                  value={nodeName}
                  InputProps={{ style: { fontSize: 24 } }} // font size of input text
                ></TextField>
                {nodeName == data?.node?.name ? (
                  <></>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{ maxWidth: '10%', backgroundColor: 'green' }}
                  >
                    ✓
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  style={{ maxWidth: '5%', backgroundColor: 'red' }}
                  onClick={removeNode}
                >
                  Delete Node
                </Button>
              </div>
              <i>
                <h4>{data?.node?.type}</h4>
              </i>
              {['Income Source', 'Expense', 'External User'].includes(data?.node?.type) ? (
              <></>
            ):(
              <div>
                <TextField
                  variant="standard"
                  id="currentBalance"
                  label="Current Balance"
                  fullWidth
                  onChange={(e) => {
                    setCurrentBalance(e.target.value)
                  }}
                  value={currentBalance?.toLocaleString()}
                  InputProps={{ style: { fontSize: 24 } }} // font size of input text
                ></TextField>
                {currentBalance === data?.node?.currentBalance ? (
                  <></>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{ maxWidth: '10%', backgroundColor: 'green' }}
                  >
                    ✓
                  </Button>
                )}
              </div>
            )}
            </form>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setGraphView(false)
                }}
              >
                Show Node Detail
              </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default NodeInspectView

/**
 * Dropdown display (maybe useful in the future so here for safe keeping)
 * <div className='full-node-info-header-section-div'>
                { openBudgetView ? 
                  <div className='downdownSection'>
                    <div className='sectionHeader'>
                      <img src={dropup} alt="" onClick={()=> toggleBudgetView(false)}/>
                      <h2>Budget</h2>
                    </div>
                    <NodeBudgetView/> 
                  </div>
                  :
                  <div className='sectionHeader'>
                    <img src={dropdown} alt="" onClick={()=> toggleBudgetView(true)}/>
                    <h2>Budget</h2>
                  </div>
                }
                
              </div>
              
              <div className='full-node-info-header-section-div'>
                { openTransactionView ?
                  <div className='downdownSection'>
                    <div className='sectionHeader'>
                      <img src={dropup} alt="" onClick={()=> toggleTransactionView(false)}/>
                      <h2>Recent Transactions</h2>
                    </div>
                    <NodeTransactionView transactions={[]}/> 
                  </div> :
                  <div className='sectionHeader'>
                    <img src={dropdown} alt="" onClick={()=> toggleTransactionView(true)}/>
                    <h2>Recent Transactions</h2>
                  </div>
                }
              </div>
              <div className='full-node-info-header-section-div'>
                { openAccountsView ?
                  <div className='downdownSection'>
                    <div className='sectionHeader'>
                      <img src={dropup} alt="" onClick={()=> toggleAccountsView(false)}/>
                        <h2>Attached Accounts</h2>
                    </div>
                    <span>Attaching Accounts is not an enabled feature yet.</span>
                  </div> :
                  <div className='sectionHeader'>
                    <img src={dropdown}
                      alt="" onClick={()=> toggleAccountsView(true)}/>
                    <h2>Attached Accounts</h2>
                  </div>
                }
            </div>
 * 
 * 
 */
