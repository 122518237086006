import React from 'react'
import { Box, TextField, Button, Alert } from '@mui/material'
import { UpdateAuthFormLogic } from './UpdateAuthFormLogic'
import Typography from '@mui/material/Typography'

export default function UpdateAuthFormView() {
  const { currentUser, handleSubmit, retraceStep, error, loading } =
    UpdateAuthFormLogic()
  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar> */}
        <Typography component="h1" variant="h5">
          Update any account information.
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={'Update Current Email: ' + currentUser.email}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Update Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            fullWidth
            name="password-confirm"
            label="Confirm Password"
            type="password"
            id="password-confirm"
            autoComplete="current-password"
          />

          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit changes
          </Button>
        </Box>
        <Button
          onClick={() => {
            retraceStep()
          }}
        >
          Return
        </Button>
      </Box>
    </>
  )
}
